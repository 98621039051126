import React from 'react'
import TabBarWrap from './style'
import { NavLink } from 'react-router-dom'

const TabBar: React.FC = () => {
  return (
    <TabBarWrap>
      <NavLink exact to='/' activeClassName='active'>
        <img className='gray' src={require("./tab_home@2x.png")} alt=""/>
        <img className='orange' src={require("./tab_home_pre@2x.png")} alt=""/>
        <p>首页</p>
      </NavLink>
      <NavLink to='/score' activeClassName='active'>
        <img className='gray' src={require("./tab_bifen@2x.png")} alt=""/>
        <img className='orange' src={require("./tab_bifen_pre@2x.png")} alt=""/>
        <p>比赛</p>
      </NavLink>
      <NavLink to='/anchor' activeClassName='active'>
        <img className='gray' src={require("./tab_zhubo@2x.png")} alt=""/>
        <img className='orange' src={require("./tab_zhubo_pre@2x.png")} alt=""/>
        <p>主播</p>
      </NavLink>
      <NavLink to='/user-center' activeClassName='active'>
        <img className='gray' src={require("./tab_my@2x.png")} alt=""/>
        <img className='orange' src={require("./tab_my_pre@2x.png")} alt=""/>
        <p>我的</p>
      </NavLink>
    </TabBarWrap>
  )
}

export default TabBar
