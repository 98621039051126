import React, {useCallback, useState} from "react";
import VerifyAlertWrap from "./style";
import {Icon} from "antd-mobile";

const VerifyAlert: React.FC<any> = ({ history, hide }) => {
  const [active, setAct] = useState<number>(0)

  const goVerify = useCallback(() => {
    const url = active === 0 ? '/user-center/apply/anchor' : '/user-center/apply/material'
    history.push(url)
  }, [active, history])

  return (
    <VerifyAlertWrap>
      <div className="box">
        <Icon type='cross' onClick={hide} className='close' />
        <div className="links">
          <div onClick={() => { setAct(0) }} className={active === 0 ? 'active link' : 'link'}>
            <img src={require('./zhubo@2x.png')} alt=""/>
            <p>认证主播</p>
          </div>
          <div onClick={() => { setAct(1) }} className={active === 1 ? 'active link' : 'link'}>
            <img src={require('./youliao@2x.png')} alt=""/>
            <p>认证有料号</p>
          </div>
        </div>
        <button onClick={goVerify}>确定</button>
      </div>
    </VerifyAlertWrap>
  )
}

export default VerifyAlert