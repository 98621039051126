import styled from 'styled-components'
import trans from '../../common/util/px'

const LoginWrap = styled.div`
  text-align: center;
  .head {
    margin-bottom: ${trans(60)};
    text-align: left;
  }
  &>h3 {
    font-size: ${trans(36)};
    color: #000000;
    padding-left: ${trans(79)};
    margin-bottom: ${trans(50)};
    text-align: left;
  }
  .phone {
    margin: 0 ${trans(66)};
    margin-bottom: ${trans(36)};
    height: ${trans(80)};
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F06E1E;
    .phone-pre {
      padding-left: ${trans(12)};
      margin-right: ${trans(20)};
      font-size: ${trans(34)};
      color: #000000;
    }
    &>input {
      flex: 1;
      font-size: ${trans(34)};
      border: none;
      outline: none;
    }
  }
  .password {
    margin: 0 ${trans(66)};
    height: ${trans(80)};
    border-bottom: 1px solid #F4F4F4;
    margin-bottom: ${trans(35)};
    overflow: hidden;
    &>input {
      width: 100%;
      height: 100%;
      font-size: ${trans(34)};
      border: none;
      outline: none;
    }
  }
  .forget {
    display: block;
    padding-left: ${trans(66)};
    font-size: ${trans(28)};
    color: #000000;
    text-align: left;
  }
  .submit {
    width: ${trans(690)};
    margin: 0 auto;
    margin-top: ${trans(197)};
    margin-bottom: ${trans(33)};
    height: ${trans(100)};
    line-height: ${trans(100)};
    border-radius: ${trans(50)};
    background-color: #F06E1E;
    text-align: center;
    font-size: ${trans(32)};
    color: #FEFFFF;
  }
  .register {
    font-size: ${trans(28)};
    color: #878787;
  }
`

export default LoginWrap