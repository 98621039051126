import styled from 'styled-components'
import trans from '../../common/util/px'
import userBg from './user-bg@2x.png'

const MyWrap = styled.div`
  padding-bottom: ${trans(96)};
  .head {
    width: 100%;
    height: ${trans(260)};
    background: url(${userBg}) center center no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    .login {
      display: block;
      width: ${trans(215)};
      margin: 0 auto;
      margin-top: ${trans(98)};
      height: ${trans(64)};
      line-height: ${trans(64)};
      border-radius: ${trans(32)};
      border: 1px solid #fff;
      text-align: center;
      font-size: ${trans(32)};
      color: #FFFFFF;
    }
    .info {
      display: flex;
      align-items: center;
      margin-top: ${trans(75)};
      padding-left: ${trans(32)};
      padding-right: ${trans(20)};
      &>img {
        width: ${trans(107)};
        height: ${trans(107)};
        margin-right: ${trans(31)};
        border-radius: 50%;
        border: ${trans(4)} solid #fff;
      }
      .center {
        &>h6 {
          margin-bottom: ${trans(24)};
          display: flex;
          align-items: center;
          font-size: ${trans(32)};
          color: #FFFFFF;
          font-weight: bold;
          &>img {
            width: ${trans(42)};
            height: ${trans(26)};
            margin-left: ${trans(18)};
          }
        }
        &>p {
          font-size: ${trans(24)};
          color: #FFFFFF;
        }
      }
    }
    .message {
      position: absolute;
      top: ${trans(20)};
      right: ${trans(25)};
      &>img {
        width: ${trans(48)};
        height: ${trans(48)};
      }
    }
  }
  .data-num {
    padding: 0 ${trans(30)};
    height: ${trans(140)};
    display: flex;
    justify-content: space-between;
    border-bottom: ${trans(10)} solid #F4F4F4;
    .num {
      display: flex;
      align-items: center;
      &>img {
        margin-right: ${trans(10)};
        width: ${trans(35)};
        height: ${trans(42)};
      }
      &>p {
        margin-right: ${trans(5)};
        font-size: ${trans(36)};
        color: #333333;
      }
      &>span {
        font-size: ${trans(30)};
        color: #888888;
      }
    }
    .score {
      padding: 0 ${trans(24)};
      height: ${trans(83)};
      display: flex;
      align-items: center;
      &>img {
        margin-right: ${trans(25)};
        width: ${trans(37)};
        height: ${trans(45)};
      }
      .right {
        &>p {
          padding-left: ${trans(33)};
          margin-bottom: ${trans(13)};
          font-size: ${trans(28)};
          color: #333333;
        }
        &>span {
          display: flex;
          align-items: center;
          font-size: ${trans(24)};
          color: #B6AE99;
          &>img {
            margin-right: ${trans(7)};
            width: ${trans(24)};
            height: ${trans(29)};
          }
        }
      }
    }
  }
  .link {
    height: ${trans(108)};
    display: flex;
    align-items: center;
    padding: 0 ${trans(30)};
    &>img {
      width: ${trans(52)};
      height: ${trans(52)};
      margin-right: ${trans(20)};
    }
    &>p {
      font-size: ${trans(30)};
      color: #333333;
    }
  }
`

export default MyWrap
