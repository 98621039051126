import React, { useMemo } from 'react'
import { PercentWrap } from './style'

interface PercentProps {
  leftNum: number
  rightNum: number
  type: number
}

const countStatus: any = {
  1: '进球',
  2: '角球',
  3: '黄牌',
  4: '红牌',
  5: '界外球',
  6: '任意球',
  7: '球门球',
  8: '点球',
  9: '换人',
  10: '比赛开始',
  11: '中场',
  12: '结束',
  13: '半场比分',
  15: '两黄变红',
  16: '点球未进',
  17: '乌龙球',
  19: '伤停补时',
  21: '射正',
  22: '射偏',
  23: '进攻',
  24: '危险进攻',
  25: '控球率',
  26: '加时赛结束',
  27: '点球大战结束'
}

const Percent: React.FC<PercentProps> = ({ leftNum, rightNum, type }) => {
  const perObj = useMemo((): any => {
    const total: number = leftNum + rightNum
    const leftPer: any = leftNum / total
    const rightPer: any = rightNum / total
    const left: number = leftPer.toFixed(2) * 100
    const right: number = rightPer.toFixed(2) * 100
    return {
      left,
      right
    }
  }, [leftNum, rightNum])

  return (
    <PercentWrap>
      <p>{ leftNum }</p>
      <div className="left-percent progress">
        <div style={{ width: perObj.left + '%' }} className="active"/>
      </div>
      <p>{ countStatus[type] }</p>
      <div className="right-percent progress">
        <div style={{ width: perObj.right + '%' }} className="active"/>
      </div>
      <p>{ rightNum }</p>
    </PercentWrap>
  )
}

export default Percent