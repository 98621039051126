import React from 'react'
import BackHeaderWrap from './style'
import {Icon} from 'antd-mobile'
import { RouteChildrenProps } from 'react-router-dom'

interface BackHeaderProps extends RouteChildrenProps {
  title: string
  back?: () => void
}

const BackHeader: React.FC<BackHeaderProps> = ({ history, title, back }) => {
  return (
    <BackHeaderWrap>
      <Icon onClick={() => {
        if (back) back()
        history.goBack()
      }} type='left' size='lg' color='#060606' /><span>{title}</span>
    </BackHeaderWrap>
  )
}

export default BackHeader