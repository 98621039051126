import React, {useCallback, useEffect, useRef, useState} from "react";
import UserInfoWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import { connect } from "react-redux";
import {Icon, Toast} from "antd-mobile";
import trans from "../../common/util/px";
import cos from '../../common/js/upload'
import {defaultAvatar} from "../../common/js/config";
import http from '../../common/js/http'
import {SetUserInfo} from "../../store/action";

interface UserInfoProps extends RouteChildrenProps{
  userInfo: any
  sdkConfig: any
  changeUserInfo(info: any): void
}

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { userInfo, sdkConfig, changeUserInfo, history } = props

  const [nickName, setName] = useState<string>('')
  const [sign, setSign] = useState<string>('')
  const [avatar, setAvatar] = useState<string>('')

  const nameInput = useRef<any>()
  const signInput = useRef<any>()
  const fileInput = useRef<any>()

  useEffect(() => {
    setName(userInfo.nick_name)
    nameInput.current.value = userInfo.nick_name || ''
    setSign(userInfo.signature)
    signInput.current.value = userInfo.profile ? userInfo.profile.signature : ''
    setAvatar(userInfo.avatar || defaultAvatar)
  }, [userInfo])

  const changeName = useCallback((e) => {
    setName(nameInput.current.value)
  }, [])

  const changeSign = useCallback((e) => {
    setSign(signInput.current.value)
  }, [])

  const upload = useCallback(() => {
    const file = fileInput.current.files[0]
    if (file.type.indexOf('image') === -1) {
      Toast.fail('请选择图片文件')
      return
    }
    const cosObj = cos()
    cosObj.putObject({
      Bucket: sdkConfig.cos_bucket,
      Region: sdkConfig.cos_region,
      Key: sdkConfig.cos_folder_image + `/${file.name}`,
      Body: file
    }, (err: any, data: any) => {
      if (err) {
        console.log(err)
        Toast.fail('上传失败')
        return
      }
      setAvatar('https://' + data.Location)
      fileInput.current.value = ''
    })
  }, [sdkConfig])

  const submit = useCallback(() => {
    http.post('/api/User/editUserInfo', {
      avatar,
      signature: sign,
      nick_name: nickName
    })
      .then(() => {
        changeUserInfo({
          ...userInfo,
          avatar,
          signature: sign,
          nick_name: nickName
        })
        history.goBack()
      })
  }, [avatar, nickName, sign, userInfo, changeUserInfo, history])

  return (
    <UserInfoWrap>
      <BackHeader title='个人资料' {...props} />
      <div className="input avatar">
        <label>头像</label>
        <div style={{ flex: 1 }} />
        <img onClick={() => { fileInput.current.click() }} src={avatar} alt=""/>
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="input">
        <label>昵称</label>
        <input onChange={changeName} type="text" ref={nameInput} />
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="input">
        <label>ID</label>
        <p>{userInfo.id}</p>
      </div>
      <div className="input" onClick={() => {
        if (userInfo.account) return
        history.push('/user-center/bind-phone')
      }}>
        <label>手机号</label>
        <span>{userInfo.account || '绑定手机号'}</span>
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="input">
        <label>个性签名</label>
        <input onChange={changeSign} type="text" ref={signInput} />
        <Icon style={{ marginLeft: trans(27) }} color='#939393' type='right' />
      </div>
      <div className="footer">
        <button onClick={submit}>保存</button>
        <p>严禁上传任何违反国家法律法规内容</p>
      </div>
      <input onChange={upload} type="file" ref={fileInput} style={{ display: 'none' }}/>
    </UserInfoWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo,
  sdkConfig: state.sdkConfig
})

const mapDispatch = (dispatch: any) => {
  return {
    changeUserInfo(info: any) {
      dispatch(SetUserInfo(info))
    }
  }
}

export default connect(mapState, mapDispatch)(UserInfo)
