import React, {useState} from "react";
import ApplyFormWrap from "./style";
import { RouteChildrenProps } from 'react-router-dom'
import {Icon} from "antd-mobile";
import BindPhone from "./bind-phone";
import Verified from "./verified";
import InputInfo from "./input-info";

const ApplyForm: React.FC<RouteChildrenProps> = ({ history, match }) => {
  const { type } = match!.params as { type: string }

  const [step, setStep] = useState<number>(1)

  return (
    <ApplyFormWrap>
      <div className="head">
        <div className="header">
          <Icon onClick={history.goBack} type='left' size='lg' color='#fff' />
          <h2>{type === 'anchor' ? '申请成为主播' : '开通有料号'}</h2>
        </div>
        <div className="step">
          <i className="line"/>
          <div className="circle">
            <div className="one">
              <i className={step >= 1 ? 'big' : ''}/>
              <p>绑定手机</p>
            </div>
            <div className="one">
              <i className={step >= 2 ? 'big' : ''}/>
              <p>实名认证</p>
            </div>
            <div className="one">
              <i className={step >= 3 ? 'big' : ''}/>
              <p>填写个人资料</p>
            </div>
          </div>
        </div>
      </div>
      {step === 1 && <BindPhone next={(step) => { setStep(step) }} />}
      {step === 2 && <Verified next={(step) => { setStep(step) }} />}
      {step === 3 && <InputInfo type={type} history={history} />}
    </ApplyFormWrap>
  )
}

export default ApplyForm