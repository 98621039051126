import React, {useEffect, useState, useCallback} from 'react'
import FilterList from './style'
import { Icon } from 'antd-mobile'
import { RouteChildrenProps } from 'react-router-dom'
import http from '../../common/js/http'
// @ts-ignore
import jsPin from 'js-pinyin'
import parseSearch from "../../common/util/parse-query";

const allKeys: any = {}

const RaceFilter: React.FC<RouteChildrenProps> = ({ history, location }) => {
  const [list, setList] = useState<any>({})
  const [selected, setSelect] = useState<any>({})

  useEffect(() => {
    http.post('/api/Football/getEventByDate')
      .then(({ data: { data } }) => {
        let list: any = {}
        data.forEach((item: any) => {
          allKeys[item.id] = true
          const pre = jsPin.getCamelChars(item.name_zh)[0]
          if (list[pre]) {
            list[pre].push(item)
          } else {
            list[pre] = [item]
          }
        })
        setList(list)
      })
  }, [])

  const keys = Object.keys(list).sort((a, b) => {
    return a.charCodeAt(0) - b.charCodeAt(0)
  })

  const pushOne = useCallback((key: number) => {
    const copy = {...selected}
    copy[key] = !copy[key]
    setSelect(copy)
  }, [selected])

  const goBack = useCallback(() => {
    const { type } = parseSearch(location.search)
    history.replace({
      pathname: '/score',
      search: `?type=${type}&eventids=${Object.keys(selected).join(',')}`
    })
  }, [location, selected, history])

  return (
    <FilterList>
      <div className="head">
        <Icon onClick={history.goBack} type='left' size='lg'/>
        <p>赛事筛选</p>
      </div>
      <div className="list">
        {
          keys.map(item => {
            return (
              <div key={item} className='cas-item'>
                <h5>{item}</h5>
                <div className="race-list">
                  {
                    list[item].map((race: any) => {
                      return <p className={selected[race.id] ? 'active' : ''} onClick={() => { pushOne(race.id) }} key={race.id}>{race.short_name_zh}</p>
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="control">
        <p onClick={() => { setSelect(allKeys)} }>全选</p>
        <p onClick={() => { setSelect({})} }>全不选</p>
        <div style={{ flex: 1 }} />
        <p>(已选中{Object.keys(selected).length}场）</p>
        <button onClick={goBack}>确定</button>
      </div>
    </FilterList>
  )
}

export default RaceFilter