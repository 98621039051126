import styled from 'styled-components'
import trans from '../../common/util/px'
import {Link} from 'react-router-dom'

const ItemWrap = styled(Link)`
  display: block;
  width: ${trans(336)};
  margin-bottom: ${trans(48)};
  .cover {
    width: 100%;
    height: ${trans(203)};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: ${trans(20)};
    .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 ${trans(12)};
      padding-top: ${trans(50)};
      height: ${trans(99)};
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: ${trans(20)};
      color: #FFFFFF;
      line-height: ${trans(20)};
      box-sizing: border-box;
      background: linear-gradient(transparent,rgba(0,0,0,.4));
      &>p{
        display: flex;
        align-items: center;
        &>img {
          width: ${trans(16)};
          height: ${trans(20)};
          margin-right: ${trans(12)};
          vertical-align: top;
          &.level {
            width: ${trans(42)};
            height: ${trans(26)};
          }
        }
      }
    }
    .room-type {
      position: absolute;
      right: ${trans(13)};
      top: ${trans(16)};
      padding: 0 ${trans(5)};
      height: ${trans(40)};
      line-height: ${trans(40)};
      border-radius: ${trans(5)};
      color: #fff;
      font-size: ${trans(20)};
      &.orange {
        background: linear-gradient(-85deg, #FFCC2F, #FFA93A);
      }
      &.blue {
        background: linear-gradient(-85deg,rgba(47,218,255,1),rgba(47,145,255,1));    
      }
      &.purple {
        background: linear-gradient(-85deg,rgba(246,96,255,1),rgba(191,39,255,1));
      }
    }
  }
  &>p {
    font-size: ${trans(30)};
    color: #333333;
  }
`

export default ItemWrap
