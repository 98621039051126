import React, {useEffect, useState} from 'react'
import DetailRankWrap from './style'
import http from '../../common/js/http'
import {defaultAvatar} from "../../common/js/config";
import levelIcon from '../../common/js/level-icon'

const icons = [require('./vip1@2x.png'), require('./vip2@2x.png'), require('./vip3@2x.png')]

interface DetailRankProps {
  anchorId: number
}

const DetailRank: React.FC<DetailRankProps> = ({ anchorId }) => {
  const [tab, setTab] = useState<number>(0)
  const [list, setList] = useState<any[]>([])

  useEffect(() => {
    if (!anchorId) return
    http.post(tab === 0 ? '/api/Intimacy/getTotalIntimacyRank' : '/api/Intimacy/getWeekIntimacyRank', {
      anchorid: anchorId
    })
      .then(({ data: { data } }) => {
        setList(data)
      })
  }, [anchorId, tab])


  return (
    <DetailRankWrap>
      <div className="tabs">
        <p onClick={() => setTab(0)} className={tab===0 ? 'active' : ''}>亲密总榜</p>
        <p onClick={() => setTab(1)} className={tab===1 ? 'active' : ''}>亲密周榜</p>
      </div>
      {
        list.map((item, index) => {
          return (
            <div className="rank-item" key={item.id}>
              {
                index < 3 ? <img src={icons[index]} alt="" className="rank-icon"/> : <p className='rank-num'>NO.{index + 1}</p>
              }
              <img src={item.user.avatar || defaultAvatar} alt="" className="avatar"/>
              <div className="right">
                <p className="top">{item.user.nick_name} <img src={levelIcon[item.user.user_level]} alt=""/></p>
                <p className="bottom"><img src={require('./ic_heart@2x.png')} alt=""/>{tab === 0 ? item.intimacy : item.intimacy_week}</p>
              </div>
            </div>
          )
        })
      }
    </DetailRankWrap>
  )
}

export default DetailRank