import React, {useCallback} from "react";
import SettingWrap from './style'
import BackHeader from '../../components/back-header/back-header'
import { RouteChildrenProps } from 'react-router-dom'
import {Icon} from "antd-mobile";
import {removeItem} from "../../common/js/storage";
import { connect } from "react-redux";
import {SetUserInfo} from "../../store/action";

interface UserSettingProps extends RouteChildrenProps {
  changeUserInfo(info: any): void
}

const UserSetting: React.FC<UserSettingProps> = (props) => {
  const logOut = useCallback(() => {
    removeItem('uid')
    removeItem('token')
    props.changeUserInfo({
      fans_count: 0,
      attent_count: 0
    })
    props.history.push('/login')
  }, [props])

  return (
    <SettingWrap>
      <BackHeader title='设置' {...props}/>
      <a href='/' className="link">
        <p>关于我们</p>
        <Icon type='right' />
      </a>
      <a href='/' className="link">
        <p>使用条款和隐私政策</p>
        <Icon type='right' />
      </a>
      <div className="logout" onClick={logOut}>退出登录</div>
    </SettingWrap>
  )
}

const mapDispatch = (dispatch: any) => {
  return {
    changeUserInfo(info: any) {
      dispatch(SetUserInfo(info))
    }
  }
}

export default connect(null, mapDispatch)(UserSetting)