import Constants from './constants'
import http from '../common/js/http'
import { Dispatch } from 'redux'
import { setItem, getItem } from '../common/js/storage'

export function initConfig(config: any) {
  return {
    type: Constants.InitConfig,
    payload: config
  }
}

export function SetCategories(categories: any[]) {
  return {
    type: Constants.SetCategories,
    payload: categories
  }
}

export function SetUserInfo(info: any) {
  return {
    type: Constants.SetUserInfo,
    payload: info
  }
}

export function SetIm(tim: any) {
  return {
    type: Constants.SetIm,
    payload: tim
  }
}

export function getConfig() {
  return (dispatch: Dispatch) => {
    // const config = getItem('config')
    // const categories = getItem('categories')
    // if (config && (Date.now() - config.createTime < 1000 * 60 * 60 * 24)) {
    //   dispatch(initConfig(config))
    //   dispatch(SetCategories(categories))
    //   return
    // }
    http.post('/api/config/getCommonConfig')
      .then(({ data: { data } }) => {
        data.config.createTime = Date.now()
        setItem('config', data.config)
        setItem('categories', data.live_category)
        dispatch(initConfig(data.config))
      })
    http.post('/webapi/live/getCategory')
      .then(({ data: { data } }) => {
        dispatch(SetCategories(data))
      })
  }
}

export function getUserInfo() {
  return (dispatch: Dispatch) => {
    const uid = getItem('uid')
    const token = getItem('token')
    if (token && uid) {
      http.post('/webapi/User/getUserInfo', {
        token,
        uid
      })
        .then(({ data: { data }}) => {
          dispatch(SetUserInfo(data))
          setItem('nick_name', data.nick_name)
        })
    }
  }
}
