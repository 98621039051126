import styled from 'styled-components'
import trans from '../../common/util/px'

const VerifyAlertWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: ${trans(454)};
    padding: ${trans(110)} ${trans(40)} ${trans(43)} ${trans(40)};
    box-sizing: border-box;
    background-color: #fff;
    border-radius: ${trans(20)};
    position: relative;
    .close {
      position: absolute;
      right: ${trans(46)};
      top: ${trans(31)};
    }
    .links {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .link {
        width: ${trans(170)};
        padding: ${trans(30)} 0;
        box-sizing: border-box;
        border: 2px solid #D2D2D2;
        border-radius: ${trans(5)};
        &.active {
          border-color: #F06E1E;
        }
        &>img {
          width: ${trans(96)};
          height: ${trans(96)};
          margin-bottom: ${trans(21)};
        }
        &>p {
         font-size: ${trans(26)};
         color: #333333;
        }
      }
    }
    &>button {
      display: block;
      width: ${trans(204)};
      margin: 0 auto;
      margin-top: ${trans(53)};
      height: ${trans(58)};
      border-radius: ${trans(29)};
      background-color: #F06E1E;
      outline: none;
      border: none;
      font-size: ${trans(28)};
      color: #FFFFFF;
    }
  }
`

export default VerifyAlertWrap