import React from "react";

interface HourPercentProps {
  mi: string
  hour: string
  type: string
  time: number
  min: number
  max: number
}

const HourPercent: React.FC<HourPercentProps> = ({ mi, hour, type , min, max, time}) => {
  // @ts-ignore
  let percent = ''
  if (time >= max) {
    percent = '100%'
  } else if (time < min) {
    percent = '0%'
  } else {
    // @ts-ignore
    percent = ((time - min) / (max - min)).toFixed(2) * 100 + '%'
  }

  return (
    <div className={`hour-percent ${type}`}>
      <span className="mi">+{mi}</span>
      <span className="hour">{hour}</span>
      <img src={percent === '100%' ? require('./success@2x.png') : require('./circle@2x.png')} alt=""/>
      <div className="bg" style={{width: percent}}/>
    </div>
  )
}

export default HourPercent
