import React, { useMemo } from 'react'
import PlanItemWrap from './style'
import {getDate} from "../../common/util/transtime";
import trans from "../../common/util/px";

interface PlanItemProps {
  item: any
  showUser?: boolean
  showRace?: boolean
  search?: string
}

const planTypes: any = {
  1: '让球',
  2: '大小',
  3: '竞彩'
}

const PlanItem: React.FC<PlanItemProps> = ({ item, showUser= true, showRace = true, search }) => {
  const percent = useMemo(() => {
    const total = item.youliaouser.plan_correct_count + item.youliaouser.plan_error_count
    if (!total) return '0%'
    return Math.round((item.youliaouser.plan_correct_count / total) * 100) + '%'
  }, [item])

  const earTime = useMemo(() => {
    const relDate = new Date(item.create_time)
    const ms = Date.now() - relDate.getTime()
    if (ms < 1000 * 60 * 60) {
      return Math.round(ms / (1000 * 60)) + '分钟前'
    }
    if (ms < 1000 * 60 * 60 * 24) {
      return Math.round(ms / (1000 * 60 * 60)) + '小时前'
    }
    return getDate(relDate.getTime() / 1000, 'yy年mm月dd日')
  }, [item])

  return (
    <PlanItemWrap onClick={(e) => {if (!showRace) e.preventDefault()}} to={'/material/detail/' + item.id + (search || '')}>
      {
        showUser ? <div className="top">
          <img src={item.youliaouser.user.avatar} alt=""/>
          <div className="right">
            <p>{ item.youliaouser.user.nick_name }</p>
            <div className="bottom">
              <p>{item.youliaouser.plan_correct_in}连红</p>
              <p>命中率{percent}</p>
            </div>
          </div>
        </div> : null
      }
      <h6 className='text-overflow' style={{ marginBottom: trans(43) }}>{item.title}</h6>
      {
        showRace && <div className="match">
          <div className="left">
            <div className="time"><span>{planTypes[item.type]}</span>{item.match.matchevent.short_name_zh}&nbsp;&nbsp;&nbsp;&nbsp;{getDate(item.match.time, 'mm-dd hh:mt')}</div>
            <div className="team">{item.match.hometeam.short_name_zh} VS {item.match.awayteam.short_name_zh}</div>
          </div>
          <button>{item.price}金币</button>
        </div>
      }
      <div className="ear-time">{earTime}</div>
    </PlanItemWrap>
  )
}

export default PlanItem