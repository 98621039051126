import styled from 'styled-components'
import trans from '../../common/util/px'

const MessageWrap = styled.div`
  height: 100%;
  .scroll{
    height: calc(100% - ${trans(111)});
    width: 100%;
    box-sizing: border-box;
    padding: ${trans(20)};
    background-color: #F4F7FF;
    overflow-y: scroll;
    position: relative;
  }
  .tips {
    line-height: ${trans(36)};
    font-size: ${trans(28)};
    color: #FF8A43;
  }
  .msg-item {
    margin: ${trans(20)} 0;
    line-height: ${trans(32)};
    font-size: ${trans(28)};
    color: #333333;
    &>span {
      color: #B3B3B3;
    }
    &>img {
      margin-right: ${trans(11)};
      width: ${trans(42)};
      height: ${trans(26)};
      vertical-align: top;
      margin-top: ${trans(3)};
    }
  }
  .form {
    width: 100%;
    height: ${trans(111)};
    display: flex;
    align-items: center;
    padding: 0 ${trans(30)};
    background-color: #fff;
    &>input {
      flex: 1;
      height: ${trans(77)};
      padding-left: ${trans(31)};
      border-radius: ${trans(38)};
      outline: none;
      border: none;
      background-color: #F5F5F5;
    }
    &>button {
      width: ${trans(120)};
      height: ${trans(70)};
      margin-left: ${trans(20)};
      border-radius: ${trans(38)};
      background-color: #EF6E1F;
      color: #fff;
      font-size: ${trans(28)};
      border: none;
      outline: none;
    }
  }
`

export default MessageWrap