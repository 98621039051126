import React from "react";
import MyMaterialWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import trans from "../../common/util/px";
import {Tabs} from "antd-mobile";
import MyList from "./list";

const tabs = [
  {
    key: '1',
    title: <div>已通过</div>
  },{
    key: '0',
    title: <div>审核中</div>
  },{
    key: '2',
    title: <div>未通过</div>
  },
]

const MyMaterial: React.FC<RouteChildrenProps> = ({ location, match, history }) => {
  return (
    <MyMaterialWrap>
      <BackHeader title='我的有料方案' history={history} location={location} match={match} />
      <Tabs tabs={tabs}
            initialPage={'1'}
            tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.1)'}}
            prerenderingSiblingsNumber={0}
            tabBarTextStyle={{ fontSize: trans(30) }}
        // onChange={(tab, index) => { changeQuery(tab.key) }}
      >
        {
          tabs.map(item => <div className="content" key={item.key}><MyList type={item.key}/></div>)
        }
      </Tabs>
    </MyMaterialWrap>
  )
}

export default MyMaterial