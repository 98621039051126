import React, { useEffect, useState, useCallback } from "react";
import TaskWrapper from "./style";
import { Icon } from "antd-mobile";
import { useHistory } from 'react-router-dom'
import HourPercent from "./watch-percent";
import http from '../../common/js/http'
import {getItem, setItem} from "../../common/js/storage";
import {connect} from "react-redux";
import {getDate} from "../../common/util/transtime";
import MiAlert from "./alert";

const dayImgs = [
  require('./0day@2x.png'),
  require('./1day@2x.png'),
  require('./2day@2x.png'),
  require('./3day@2x.png'),
  require('./4day@2x.png'),
  require('./5day@2x.png'),
  require('./6day@2x.png'),
  require('./7day@2x.png'),
]

interface TaskProps {
  userInfo: any
}

const levelTime = {
  1: {
    min: 0,
    max: 30 * 60 * 1000
  },
  2: {
    min: 30 * 60 * 1000,
    max: 60 * 60 * 1000
  },
  3: {
    min: 60 * 60 * 1000,
    max: 1.5 * 60 * 60 * 1000
  },
  4: {
    min: 1.5 * 60 * 60 * 1000,
    max: 2 * 60 * 60 * 1000
  },
  5: {
    min: 2 * 60 * 60 * 1000,
    max: 2.5 * 60 * 60 * 1000
  },
  6: {
    min: 2.5 * 60 * 60 * 1000,
    max: 3 * 60 * 60 * 1000
  },
  7: {
    min: 3 * 60 * 60 * 1000,
    max: 3.5 * 60 * 60 * 1000
  },
  8: {
    min: 3.5 * 60 * 60 * 1000,
    max: 4 * 60 * 60 * 1000
  },
  9: {
    min: 4 * 60 * 60 * 1000,
    max: 4.5 * 60 * 60 * 1000
  },
  10: {
    min: 4.5 * 60 * 60 * 1000,
    max: 5 * 60 * 60 * 1000
  }
}


const Task: React.FC<TaskProps> = ({ userInfo }) => {
  const history = useHistory()

  const [checkIn, setCheckIn] = useState<number>(0)
  const [checkInTimes, setTimes] = useState<number>(0)
  const [stage, setStage] = useState<any>({})
  const [canGetGift, setCanGetGift] = useState<number>(0)
  const [canGetWatch, setCanGetWatch] = useState<number>(0)
  const [watchTime, setWatch] = useState<number>(Number(getItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}watchTime`)))
  const [danmu, setDanmu] = useState<boolean>(false)
  const [showAlert, setAlert] = useState<boolean>(false)
  const [award, setAward] = useState<number>(0)
  const [alertType, setAlertType] = useState<number>(0)

  const checkWatch = useCallback((stages: any) => {
    const level = watchTime / (30 * 60 * 1000)
    if (level <  stages.watch_stage) {
      let time = stages.watch_stage * 30 * 60 * 1000
      setWatch(time)
      if (stages.watch_stage === 10) {
        setCanGetWatch(2)
      }
      setItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}watchTime`, time)
      return
    }
    // @ts-ignore
    if (stages.watch_stage + 1 <= 10 && level > stages.watch_stage && watchTime >= levelTime[stages.watch_stage + 1].max) {
      setCanGetWatch(1)
      return
    }
    // @ts-ignore
    if (watchTime >= 5 * 60 * 60 * 1000 && stages.watch_stage === 10) {
      setCanGetWatch(2)
    }
  }, [watchTime, userInfo])

  const getWatchMi = useCallback(() => {
    if (canGetWatch !== 1) return
    const level = Math.floor(watchTime / (30 * 60 * 1000))
    http.post('/api/task/getWatchLiveAward', {
      stage: level <= 10 ? level : 10
    })
      .then(({ data: { data } }) => {
        setStage({
          ...stage,
          watch_stage: level > 10 ? 10 : level
        })
        setAlert(true)
        setAward(data.award)
        setAlertType(1)
        setCanGetWatch(level < 10 ? 0 : 2)
      })
  }, [watchTime, stage, canGetWatch])

  useEffect(() => {
    http.post('/api/task/getTaskInfo')
      .then(({ data: { data } }) => {
        setCheckIn(data.checkin)
        setTimes(data.checkin_continuous)
        setStage(data.stages)
        setCanGetGift(data.award.gift_award)
        setWatch(Number(getItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}watchTime`)))
        if (userInfo.id) {
          checkWatch(data.stages)
          const damu = getItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}danmu`)
          setDanmu(damu)
        }
      })
  }, [checkWatch, userInfo])

  const sign = useCallback(() => {
    if (checkIn) return
    http.post('/api/task/checkin')
      .then(({data: { data }}) => {
        setCheckIn(1)
        setAlert(true)
        setAward(data.award)
        setAlertType(0)
        setTimes(checkInTimes + 1)
      })
  }, [checkIn, checkInTimes])

  const danmuMi = useCallback(() => {
    if (!danmu) return
    http.post('/api/task/getChatAward')
      .then(({ data: { data } }) => {
        setStage({
          ...stage,
          chat_stage: 1
        })
        setAlert(true)
        setAward(data.award)
        setAlertType(1)
      })
  }, [stage, danmu])

  const giftMi = useCallback(() => {
    if (!canGetGift) return
    http.post('/api/task/getSendGiftAward')
      .then(({data: { data }}) => {
        setStage({
          ...stage,
          gift_stage: 1
        })
        setAlert(true)
        setAward(data.award)
        setAlertType(1)
      })
  }, [stage, canGetGift])

  return (
    <TaskWrapper>
      <div className="sign">
        <Icon type="left" onClick={() => history.goBack()} className="back" size='lg' />
        <div className="circle" style={{ backgroundImage: `url(${dayImgs[checkInTimes]})` }}>
          <p>已连续签到</p>
          <span>{checkInTimes}</span>
        </div>
        <div className="btn" onClick={sign}>{!checkIn ? '立即签到' : '已签到'}</div>
      </div>
      <h4 className="sub-title">每日赚米</h4>
      <div className="video">
        <img src={require('./watch_live@2x.png')} alt=""/>
        <div className="right">
          <div className="top">
            <p>观看直播(<span>{stage.watch_stage}</span>/10)</p>
            <div onClick={getWatchMi} className={`btn ${canGetWatch === 1 ? 'active' : ''}`}>{canGetWatch === 0 ? '未完成' : canGetWatch === 1 ? '领取' : '已领取'}</div>
          </div>
          <div className="percent">
            <HourPercent type="half" hour="30m" mi="100" min={0} max={30 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="1h" mi="100" min={30 * 60 * 1000} max={60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="1.5h" mi="100" min={60 * 60 * 1000} max={1.5 * 60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="2h" mi="100" min={1.5 * 60 * 60 * 1000} max={2 * 60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="2.5h" mi="100" min={2 * 60 * 60 * 1000} max={2.5 * 60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="3h" mi="100" min={2.5 * 60 * 60 * 1000} max={3 * 60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="3.5h" mi="100" min={3 * 60 * 60 * 1000} max={3.5 * 60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="4h" mi="100" min={3.5 * 60 * 60 * 1000} max={4 * 60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="4.5h" mi="100" min={4 * 60 * 60 * 1000} max={4.5 * 60 * 60 * 1000} time={watchTime} />
            <HourPercent type="" hour="5h" mi="100" min={4.5 * 60 * 60 * 1000} max={5 * 60 * 60 * 1000} time={watchTime} />
          </div>
        </div>
      </div>
      <div className="gift">
        <img className="icon" src={require('./send_gift@2x.png')} alt=""/>
        <div className="right">
          <h6>赠送礼物(<span>{stage.gift_stage}</span>/1)</h6>
          <span>每次赠送奖励：300米粒</span>
        </div>
        {stage.gift_stage ? <img className='btn' src={require('./btn_received@2x.png')} alt=""/> : null}
        {!stage.gift_stage ? <img onClick={giftMi} className="btn" src={canGetGift ? require('./btn_receive @2x.png') : require('./btn_unfinish@2x.png')} alt=""/> : null}
    </div>
      <div className="gift">
        <img className="icon" src={require('./barrage @2x.png')} alt=""/>
        <div className="right">
          <h6>发送弹幕(<span>{stage.chat_stage}</span>/1)</h6>
          <span>每次赠送奖励：300米粒</span>
        </div>
        {stage.chat_stage ? <img className="btn" src={require('./btn_received@2x.png')} alt=""/> : null}
        {!stage.chat_stage ? <img className="btn" onClick={danmuMi} src={danmu ? require('./btn_receive @2x.png') : require('./btn_unfinish@2x.png')} alt=""/> : null}
      </div>
      {showAlert ? <MiAlert type={alertType} mi={award} close={() => {setAlert(false)}} /> : null}
    </TaskWrapper>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo
})

export default connect(mapState, null)(Task)
