export default [
  '',
  require('../imgs/1@3x.png'),
  require('../imgs/2@3x.png'),
  require('../imgs/3@3x.png'),
  require('../imgs/4@3x.png'),
  require('../imgs/5@3x.png'),
  require('../imgs/6@3x.png'),
  require('../imgs/7@3x.png'),
  require('../imgs/8@3x.png'),
  require('../imgs/9@3x.png'),
  require('../imgs/10@3x.png'),
  require('../imgs/11@3x.png'),
  require('../imgs/12@3x.png'),
  require('../imgs/13@3x.png'),
  require('../imgs/14@3x.png'),
  require('../imgs/15@3x.png'),
  require('../imgs/16@3x.png'),
  require('../imgs/17@3x.png'),
  require('../imgs/18@3x.png'),
  require('../imgs/19@3x.png'),
  require('../imgs/20@3x.png')
]


export const anchorLevel = [
  '',
  require('../../assets/img/anchor-level/1@2x.png'),
  require('../../assets/img/anchor-level/2@2x.png'),
  require('../../assets/img/anchor-level/3@2x.png'),
  require('../../assets/img/anchor-level/4@2x.png'),
  require('../../assets/img/anchor-level/5@2x.png'),
  require('../../assets/img/anchor-level/6@2x.png'),
  require('../../assets/img/anchor-level/7@2x.png'),
  require('../../assets/img/anchor-level/8@2x.png'),
  require('../../assets/img/anchor-level/9@2x.png'),
  require('../../assets/img/anchor-level/10@2x.png'),
  require('../../assets/img/anchor-level/11@2x.png'),
  require('../../assets/img/anchor-level/12@2x.png'),
  require('../../assets/img/anchor-level/13@2x.png'),
  require('../../assets/img/anchor-level/14@2x.png'),
  require('../../assets/img/anchor-level/15@2x.png'),
  require('../../assets/img/anchor-level/16@2x.png'),
  require('../../assets/img/anchor-level/17@2x.png'),
  require('../../assets/img/anchor-level/18@2x.png'),
  require('../../assets/img/anchor-level/19@2x.png'),
  require('../../assets/img/anchor-level/20@2x.png')
]
