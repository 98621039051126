import styled from 'styled-components'
import trans from '../../common/util/px'

const HomeWrap = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: ${trans(96)};
  .icon-search {
    position: fixed;
    right: ${trans(30)};
    top: ${trans(22)};
  }
  .am-tabs-default-bar {
    width: 88vw;
    overflow: hidden;
  }
  .content-wrap {
    height: 100%;
    overflow-y: scroll;
  }
`

export default HomeWrap
