import React, { Fragment, useEffect, useState } from 'react'
import http from '../../common/js/http'
import {defaultAvatar} from "../../common/js/config";
import { Link } from "react-router-dom";

interface RaceAnchorProps {
  matchId: string
}

const RaceAnchor: React.FC<RaceAnchorProps> = ({ matchId }) => {
  const [anchors, setAnchors] = useState<Array<any>>([])

  useEffect(() => {
    http.post('/api/football/getMatchAnchors', {
      matchid: matchId
    })
      .then(({ data: { data } }) => {
        setAnchors(data)
      })
  }, [matchId])

  return (
    <Fragment>
      <div style={{height: '20px'}}></div>
      {
        anchors.map(item => {
          return <Link className="anchor-item" to={'/live-detail/' + item.id} key={item.id}>
            <img className="avatar" src={item.avatar || defaultAvatar} alt=""/>
            <div className="center">
              <div className="top">
                <p className="text-overflow">{item.nick_name}</p>
                {item.live && <span><img src={require('./hot@2x.png')} alt=""/>{item.live.hot}</span>}
              </div>
              <div className="bottom text-overflow">{item.live ? item.live?.title : ' '}</div>
            </div>
            {item.live && <img className="btn" src={require('./btn_live@2x.png')} alt=""/>}
          </Link>
        })
      }
    </Fragment>
  )
}

export default RaceAnchor
