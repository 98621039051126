import React, {useCallback, useEffect, useRef, useState} from "react";
import LiveRecordWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import ScrollWrap from "../../components/scroll-wrap/scroll-wrap";
import http from '../../common/js/http'
import {getDuration} from "../../common/util/transtime";
import BottomLoading from "../../components/bottom-loading/bottom-loading";

const LiveRecord: React.FC<RouteChildrenProps> = ({ history, match, location }) => {

  const [list, setList] = useState<any[]>([])
  const [showLoading, setLoading] = useState<boolean>(false)
  const page = useRef<number>(1)
  const backUp = useRef<any[]>([])

  const getList = useCallback((loading = false) => {
    return http.post('/webApi/User/getLiveLog', {
      page: page.current,
      size: 10,
      hideLoading: loading
    })
      .then(({ data: { data } }) => {
        backUp.current = [...backUp.current, ...data.list]
        setLoading(data.list.length >= 10)
        setList([...backUp.current])
      })
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const loadMore = useCallback((): Promise<any> => {
    if (!showLoading) return Promise.resolve()
    page.current++
    return getList(true)
  }, [showLoading, getList, page])

  return (
    <LiveRecordWrap>
      <BackHeader title='直播记录' history={history} location={location} match={match} />
      <div className="content">
        <ScrollWrap loadMore={loadMore}>
          {
            list.map(item => {
              return (
                <div className='item' key={item.id}>
                  <div className="time">{item.start_time}</div>
                  <p>直播标题：{item.title}</p>
                  <p>直播收益：{item.gift_profit}</p>
                  <p>直播时长：{getDuration((item.end_stamp - item.start_stamp) * 1000, 'hh:mm:ss')}</p>
                </div>
              )
            })
          }
          {showLoading && <BottomLoading/>}
        </ScrollWrap>
      </div>
    </LiveRecordWrap>
  )
}

export default LiveRecord