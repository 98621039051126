import React, {useCallback, MouseEvent, useEffect} from 'react'
import MyWrap from './style'
import { connect } from "react-redux";
import { Link, RouteChildrenProps } from "react-router-dom";
import { Icon, Modal } from "antd-mobile";
import ReactDOM from "react-dom";
import TabBar from "../../components/tabbar/tabbar";
import {defaultAvatar} from "../../common/js/config";
import {Dispatch} from "redux";
import { getUserInfo, SetUserInfo } from "../../store/action";
import {removeItem} from "../../common/js/storage";

interface MyProps extends RouteChildrenProps {
  userInfo: any,
  getUserInfo(): void
  setUserInfo(info: any): void
}

const alert = Modal.alert

const My: React.FC<MyProps> = ({ userInfo, history, getUserInfo, setUserInfo }) => {
  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const checkLogin = useCallback((e: MouseEvent) => {
    if (!userInfo.id) {
      e.preventDefault()
      alert('您还未登录', '请登录使用完整功能', [
        {text: '取消', onPress: () => {}},
        {text: '确定', onPress: () => { history.push('/login') }}
      ])
    }
  }, [userInfo, history])

  const logOut = useCallback(() => {
    removeItem('uid')
    removeItem('token')
    history.push('/login')
    setUserInfo({})
    window.location.reload()
  }, [history, setUserInfo])

  return (
    <MyWrap>
      <div className="head">
        {
          !userInfo.id ? <Link className='login' to='/login'>登录/注册</Link> :
            <Link to='/user-center/info' className='info'>
              <img src={userInfo.avatar || defaultAvatar} alt=""/>
              <div className="center">
                <h6>{userInfo.nick_name}{userInfo.vip_date || userInfo.svip_date ? <img src={require('./vip@2x.png')} alt="" /> : null}</h6>
                <p>ID:{userInfo.id}</p>
              </div>
              <div style={{ flex: 1 }} />
              <Icon type='right' size='md' color='#FCBE31' />
            </Link>
        }
        {/*{userInfo.id && <Link className='message' onClick={(e) => e.stopPropagation()} to='/user-center/message'><img src={require('./ic_message@2x.png')} alt=""/></Link>}*/}
      </div>
      <div className="data-num">
        <Link onClick={(e) => { if (userInfo.fans_count <= 0) e.preventDefault() }} className='num' to='/user-center/focus'>
          <p>{userInfo.attent_count}</p>
          <span>关注</span>
        </Link>
        <div className="num">
          <img src={require('./coin@2x.png')} alt=""/>
          <p>{userInfo.gold}</p>
          <span>米粒</span>
        </div>
        <div className="num">
          <p>{userInfo.fans_count}</p>
          <span>粉丝</span>
        </div>
      </div>
      <Link className='link' onClick={checkLogin} to={'/user-center/task'}>
        <img src={require('./ic_mission@2x.png')} alt=""/>
        <p>任务中心</p>
        <div style={{ flex: 1 }} />
        <Icon type='right' size='md' color='#B0B0B0' />
      </Link>
      <Link className='link' onClick={checkLogin} to={'/user-center/info'}>
        <img src={require('./ic_profile@2x.png')} alt=""/>
        <p>个人资料</p>
        <div style={{ flex: 1 }} />
        <Icon type='right' size='md' color='#B0B0B0' />
      </Link>
      <Link className='link' onClick={checkLogin} to="/user-center/message">
        <img src={require('./ic_message@2x.png')} alt=""/>
        <p>我的消息</p>
        <div style={{ flex: 1 }} />
        <Icon type='right' size='md' color='#B0B0B0' />
      </Link>
      <Link className='link' onClick={checkLogin} to="/user-center/focus">
        <img src={require('./ic_follow@2x.png')} alt=""/>
        <p>我的关注</p>
        <div style={{ flex: 1 }} />
        <Icon type='right' size='md' color='#B0B0B0' />
      </Link>
      {!userInfo.is_anchor ? <Link className='link' onClick={checkLogin} to="/user-center/apply/anchor">
        <img src={require('./ic_apply@2x.png')} alt=""/>
        <p>申请成为主播</p>
        <div style={{ flex: 1 }} />
        <Icon type='right' size='md' color='#B0B0B0' />
      </Link> : null}
      <a href="https://fb.nasinet.com/download" className='link'>
        <img src={require('./download.png')} alt=""/>
        <p>下载APP</p>
        <div style={{ flex: 1 }} />
        <Icon type='right' size='md' color='#B0B0B0' />
      </a>
      <div className='link' onClick={logOut}>
        <img src={require('./ic_setting@2x.png')} alt=""/>
        <p>退出登录</p>
        <div style={{ flex: 1 }} />
        <Icon type='right' size='md' color='#B0B0B0' />
      </div>
      {ReactDOM.createPortal(<TabBar/>, document.body)}
    </MyWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo
})

const mapAction = (dispatch: Dispatch) => {
  return {
    getUserInfo() {
      dispatch<any>(getUserInfo())
    },
    setUserInfo(user: any) {
      dispatch<any>(SetUserInfo(user))
    }
  }
}

export default connect(mapState, mapAction)(My)
