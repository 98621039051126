import styled from 'styled-components'
import trans from '../../common/util/px'

const SettingWrap = styled.div`
  height: 100vh;
  background: #F4F4F4;
  .link {
    display: flex;
    padding: 0 ${trans(30)};
    height: ${trans(120)};
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #F4F4F4;
    font-size: ${trans(30)};
    color: #333333;
  }
  .logout {
    margin-top: ${trans(20)};
    height: ${trans(102)};
    line-height: ${trans(102)};
    background-color: #fff;
    text-align: center;
    font-size: ${trans(30)};
    color: #333333;
  }
`

export default SettingWrap