import React, {useEffect, useState, Fragment, useCallback, useRef, useMemo} from "react";
import WalletWrap from "./style";
import {Icon, Toast} from "antd-mobile";
import { Link, RouteChildrenProps } from "react-router-dom";
import { connect } from "react-redux";
import http from '../../common/js/http'
import ReactDom from 'react-dom'
import VerifyAlert from "../../components/verify-alert/verify-alert";

interface WalletProps extends RouteChildrenProps {
  userInfo: any
  sdkConfig: any
}

const Wallet: React.FC<WalletProps> = ({ userInfo, history, sdkConfig }) => {
  const [verifyIInfo, setVerify] = useState<any>({})
  const [showAlert, setAlert] = useState<any>(false)
  const [diamond, setDiamond] = useState<string>('')

  const inputRef = useRef<any>()

  useEffect(() => {
    if (userInfo.is_anchor || userInfo.is_youliao) {
      http.post('/webapi/withdraw/getAccount')
        .then(({ data: { data } }) => {
          setVerify(data)
        })
    }
  }, [userInfo])

  const money = useMemo(() => {
    return (Number(diamond) / sdkConfig.exchange_rate).toFixed(2)
  }, [sdkConfig, diamond])

  const submit = useCallback(() => {
    if (!userInfo.is_anchor) {
      Toast.fail('请先完成实名认证', 1, undefined, false)
      return
    }
    if (money < sdkConfig.withdraw_min) {
      Toast.fail(`提现金额不能小于${sdkConfig.withdraw_min}`, 1, undefined, false)
      return
    }
    http.post('/webapi/withdraw/withdraw', {
      cash: money,
      diamond,
      alipay_account: verifyIInfo.alipay_account,
      alipay_name: verifyIInfo.alipay_name
    })
      .then(() => {
        Toast.success('申请提现成功')
      })
  }, [userInfo, money, sdkConfig, diamond, verifyIInfo])

  const changeDiamond = useCallback(() => {
    setDiamond(inputRef.current.value)
  }, [])

  const goEdit = useCallback(() => {
    history.push({
      pathname: '/user-center/edit-account',
      state: verifyIInfo
    })
  }, [verifyIInfo, history])

  return (
    <WalletWrap>
      <div className="head">
        <Icon type='left' size='lg' onClick={history.goBack} />
        <p>我的钱包</p>
        <Link to='/user-center/withdraw'>提现记录</Link>
      </div>
      <div className="nums">
        <div className="num">
          <span>累计钻石</span>
          <p>{userInfo.diamond_total}</p>
        </div>
        <i/>
        <div className="num">
          <span>可提取钻石数</span>
          <p>{userInfo.diamond}</p>
        </div>
      </div>
      <div className="input">
        <label>提现金额</label>
        <input ref={inputRef} onChange={changeDiamond} value={diamond} type="text" placeholder='请输入要提取的钻石数' />
      </div>
      <p className="out-money">可到账金额 <span>¥{money}</span></p>
      <div className="account">
        <div className="sub-title">
          <h4>我的提现账户</h4>
          {userInfo.is_anchor || userInfo.is_youliao ? <div onClick={goEdit} className='link'>管理账户</div> : null}
        </div>
        {
          verifyIInfo && userInfo.is_anchor ? <Fragment>
            <p className="desc">支付宝账号：<span>{verifyIInfo.alipay_account}</span></p>
            <p className="desc">支付宝姓名：<span>{verifyIInfo.alipay_name}</span></p>
          </Fragment> : <div className='verify'>
            <p>您还未进行实名认证</p>
            <span onClick={() => { setAlert(true) }}>立即完成 <Icon type='right'/></span>
          </div>
        }
      </div>
      <div className="rule">
        <h6>
          <i/>
          <span>提现规则</span>
          <i/>
        </h6>
        <p className="tips">
          1.提现须绑定手机号和支付宝，且必须实名认证，因用户个人原
          因误填提现账户信息导致的后果，“钠斯足球直播官方”不承担任何责任
          2.申请提现一般会在24小时内发放至账户，如遇周末或节假日会
          顺延至工作日发放。
          3.提现比例可能会发生变动，以最新规则为准。
          4.最终解释权归“钠斯足球直播官方”所有。
        </p>
      </div>
      <button className="submit" onClick={submit}>立即提现</button>
      {
        showAlert ? ReactDom.createPortal(<VerifyAlert hide={() => {setAlert(false)}} history={history} />, document.body) : null
      }
    </WalletWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo,
  sdkConfig: state.sdkConfig
})

export default connect(mapState, null)(Wallet)
