import React, {useState, useCallback, useRef, DetailedHTMLProps} from 'react'

interface ScrollProps {
  children: any
  loadMore(): Promise<any>
}


const ScrollWrap: React.FC<ScrollProps> = ({ children, loadMore }) => {
  const [height, setHeight] = useState<number>(0)
  const scrollWrap = useRef<DetailedHTMLProps<any, any>>()

  const scroll = useCallback(() => {
    const scrollTop = scrollWrap.current.scrollTop
    const scrollHeight = scrollWrap.current.scrollHeight
    if (scrollHeight - scrollTop <= (scrollWrap.current.clientHeight + 20)) {
      if (height !== scrollHeight) {
        console.log('end')
        setHeight(scrollHeight)
        loadMore()
          .catch((err) => {
            setHeight(0)
          })
      }
    }
  }, [height, loadMore])

  return (
    <div onScroll={scroll} ref={scrollWrap} style={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
      {children}
    </div>
  )
}

export default ScrollWrap