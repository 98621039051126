import styled from 'styled-components'
import trans from '../../common/util/px'

const SysMessageWrap = styled.div`
  .list {
    width: 100%;
    height: calc(100vh - ${trans(90)});
    .msg {
      display: block;
      margin: 0 ${trans(30)};
      margin-top: ${trans(58)};
      .date {
        margin-bottom: ${trans(22)};
        text-align: center;
        font-size: ${trans(24)};
        color: #989898;
      }
      .content {
        border-radius: ${trans(5)};
        overflow: hidden;
        box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.06);
        .img {
          width: 100%;
          height: ${trans(254)};
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        &>h4 {
          padding: 0 ${trans(30)};
          margin-top: ${trans(37)};
          margin-bottom: ${trans(27)};
          font-size: ${trans(28)};
          color: #333333;
        }
        &>p {
          padding: 0 ${trans(30)};
          margin-bottom: ${trans(28)};
          font-size: ${trans(24)};
          color: #666666;
        }
        &>span {
          padding: 0 ${trans(28)};
          display: flex;
          height: ${trans(60)};
          align-items: center;
          border-top: 1px solid #ECECEC;
          color: #333333;
          font-size: ${trans(24)};
        }
      }
    }
  }
`

export default SysMessageWrap