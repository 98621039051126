import styled from 'styled-components'
import trans from '../../common/util/px'

const WithdrawRecordWrap = styled.div`
  height: calc(100vh - ${trans(90)});
  overflow: hidden;
  background-color: #F4F4F4;
  .item {
    margin: 0 ${trans(30)};
    margin-top: ${trans(17)};
    padding: ${trans(30)} ${trans(27)};
    background-color: #fff;
    .status {
      display: flex;
      justify-content: space-between;
      margin-bottom: ${trans(41)};
      &>p {
        font-size: ${trans(24)};
        color: #969696;
      }
      &>span {
        width: ${trans(97)};
        height: ${trans(46)};
        line-height: ${trans(46)};
        text-align: center;
        color: #fff;
        font-size: ${trans(24)};
      }
    }
    &>p {
      margin-top: ${trans(34)};
      font-size: ${trans(28)};
      color: #333333;
    }
  }
`

export default WithdrawRecordWrap