import styled from 'styled-components'
import trans from '../../common/util/px'

const RecWrap = styled.div`
  .icon-search {
    height: ${trans(71)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    &>img {
      height: 100%;
    }
  }
  .banner-wrap {
    width: 100%;
    height: ${trans(320)};
    align-items: center;
  }
  .my-carousel {
    height: 100% !important;
  }
  .banner-item {
    display: block;
    width: 100%;
    height: ${trans(320)};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .sub-title {
    margin-bottom: ${trans(35)};
    display: flex;
    align-content: center;
    font-size: ${trans(30)};
    color: #333333;
    &>i {
      width: ${trans(5)};
      height: ${trans(27)};
      margin-right: ${trans(11)};
      border-radius: ${trans(5)};
      background-color: #F06E1E;
    }
  }
  .anchors {
    padding: ${trans(26)};
    border-bottom: ${trans(15)} solid #F4F4F4;
    .list {
      margin-top: ${trans(28)};
      display: flex;
      &>a {
        display: block;
        margin-right: ${trans(45)};
        width: ${trans(97)};
        &:last-child {
          margin-right: 0;
        }
        &>p {
          margin-top: ${trans(17)};
          text-align: center;
          font-size: ${trans(24)};
          color: #333333;
        }
      }
      .icon {
        width: ${trans(97)};
        height: ${trans(97)};
        padding: ${trans(4)};
        border-radius: 50%;
        border: ${trans(3)} solid #FEC249;
        position: relative;
        .avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .voice {
          position: absolute;
          right: ${trans(6)};
          bottom: -2px;
          width: ${trans(28)};
          height: ${trans(28)};
        }
      }
    }
  }
  .hot {
    padding: ${trans(40)} ${trans(30)} ${trans(22)} ${trans(30)};
    border-bottom: ${trans(15)} solid #F4F4F4;
    &.no-border {
      padding-bottom: 0;
      border-bottom: none;
    }
    &:last-child {
      border-bottom: none;
    }
    .hot-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  .race-carousel {
    width: 100%;
    border-bottom: ${trans(15)} solid #F4F4F4;
    .race-banner {
      display: flex;
      position: relative;
      .dot {
        position: absolute;
        right: ${trans(30)};
        top: ${trans(116)};
        width: ${trans(24)};
        height: ${trans(76)};
      }
      .race {
        flex: 1;
        &:first-child {
          border-right: 1px solid #F4F4F4;
        }
        .title {
          display: flex;
          justify-content: space-between;
          padding: 0 ${trans(30)};
          padding-top: ${trans(30)};
          &>span {
            font-size: ${trans(26)};
            color: #828282;
            &:last-child {
              font-size: ${trans(24)};
            }
          }
        }
        .team {
          margin: ${trans(35)} 0;
          display: flex;
          padding: 0 ${trans(30)};
          align-items: center;
          font-size: ${trans(28)};
          color: #000000;
          &>img {
            width: ${trans(50)};
            height: ${trans(50)};
            border-radius: 50%;
          }
          &>p {
            flex: 1;
          }
        }
      }
    }
  }
`

export default RecWrap
