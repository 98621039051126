import React, { useCallback, useMemo } from 'react'
import { Modal, Toast } from 'antd-mobile'
import ItemWrap from './style'
import CryptoJS from 'crypto-js'
import { useHistory } from 'react-router-dom'
import { anchorLevel } from "../../common/js/level-icon";

const { prompt, alert } = Modal;

const roomType = ['普通房间', '私密房间', '付费房间']
const typeClass = ['orange', 'blue', 'purple']

const LiveItem: React.FC<{ live: any }> = ({ live }) => {
  const history = useHistory()

  const goRoom = useCallback((e: any) => {
    if (live.room_type === 2) {
      e.preventDefault()
      alert('提示', '当前房间为付费房间，请前往APP观看', [
        {text: '取消', onPress: () => {}},
        {text: '确定', onPress: () => { window.location.href = '/download' }}
      ])
      return
    }
    if (live.room_type !== 0) {
      e.preventDefault()
      prompt('私密房间', '请输入房间密码',
        [
          {
            text: '取消',
            onPress: () => new Promise<void>((resolve) => {
              resolve();
            }),
          },
          {
            text: '确定',
            onPress: (password: any) => new Promise<void>((resolve, reject) => {
              if (CryptoJS.MD5(password).toString() === live.password) {
                history.push('/live-detail/' + live.anchorid)
                resolve()
              } else {
                reject();
                Toast.fail('密码错误')
              }
            }),
          },
        ], 'secure-text', '', ['请输入密码'])
    }
  }, [live, history])

  const hot = useMemo(() => {
    if (live.hot >= 10000) {
      return (live.hot / 10000).toFixed(1) + 'w'
    }
    return live.hot
  }, [live])

  return (
    <ItemWrap to={`/live-detail/${live.anchorid}`} onClick={goRoom}>
      <div className="cover" style={{backgroundImage: `url(${live.thumb})`}}>
        <div className="bottom">
          <p className="text-overflow"><img className="level" src={anchorLevel[live.anchor.anchor_level]} alt=""/>{ live.anchor.nick_name }</p>
          <p><img src={require('./ic_hot@2x.png')} alt=""/>{ hot }</p>
        </div>
        <p className={`room-type ${typeClass[live.room_type]}`}>{ roomType[live.room_type] }</p>
      </div>
      <p className='text-overflow'>{ live.title }</p>
    </ItemWrap>
  )
}

export default LiveItem
