import React from "react";
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)

interface RaceItemProps {
  race: any
}

const RaceItem: React.FC<RaceItemProps> = ({race}) => {
  const history = useHistory()

  const date = dayjs(new Date(race.time * 1000))

  return <div className='race' onClick={() => history.push(`/score/detail/${race.id}`)}>
    <p className="title">
      <span>{race.matchevent.short_name_zh}</span>
      <span>{date.isToday() ? '今天  ' + date.format('HH:mm') : date.add(0, 'day').isTomorrow() ? '明天  '  + date.format('HH:mm') : date.add(0, 'day').isYesterday() ? '昨天  '  + date.format('HH:mm') : date.format('MM-DD HH:mm')}</span>
    </p>
    <div className="team">
      <img src={race.hometeam.logo} alt=""/>
      <p className="text-overflow">{race.hometeam.name_zh}</p>
    </div>
    <div className="team">
      <img src={race.awayteam.logo} alt=""/>
      <p>{race.awayteam.name_zh}</p>
    </div>
  </div>
}

export default RaceItem
