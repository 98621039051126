/**
 * @description 时间转换
 * @param timeStamp{number}
 * @param tem{string}
 */

export const getDate = (timeStamp: number, tem: string) => {
  const date = new Date(timeStamp * 1000)
  let year = date.getFullYear()
  let month: number | string = date.getMonth() + 1
  month = month > 9 ? month : '0' + month
  let day: string | number = date.getDate()
  day = day > 9 ? day : '0' + day
  let hour: string | number = date.getHours()
  hour = hour > 9 ? hour : '0' + hour
  let min: string | number = date.getMinutes()
  min = min > 9 ? min : '0' + 9
  let sec: string | number = date.getSeconds()
  sec = sec > 9 ? sec : '0' + sec
  return tem.replace('yy', year.toString()).replace('mm', month.toString()).replace('dd', day.toString())
    .replace('hh', hour.toString()).replace('mt', min.toString()).replace('ss', sec.toString())
}

export const getDuration = (stamp: number, tem: string) => {
  const hour = Math.floor(stamp / (1000 * 60 * 60))
  let ms = stamp - hour * 1000 * 60 * 60
  const min = Math.floor(ms / (1000 * 60))
  ms = ms - min * 1000 * 60
  const sec = Math.floor(ms / 1000)
  return tem.replace('hh', hour > 9 ? hour.toString() : '0' + hour).replace('mm', min > 9 ? min.toString() : '0' + min).replace('ss', sec > 9 ? sec.toString() : '0' + sec)
}
