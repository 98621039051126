import React, {useCallback, useEffect, useRef, useState} from "react";
import ScrollWrap from "../../components/scroll-wrap/scroll-wrap";
import http from '../../common/js/http'
import { IncomeItemWrap } from "./style";
import BottomLoading from "../../components/bottom-loading/bottom-loading";

interface IncomeListProps {
  type: string
}

const IncomeList: React.FC<IncomeListProps> = ({ type }) => {
  const url = type === '0' ? '/webApi/User/giftProfit' : '/webApi/User/youliaoProfit'

  const [list, setList] = useState<any[]>([])
  const [showLoading, setLoading] = useState<boolean>(false)

  const page = useRef<number>(1)
  const backUp = useRef<any[]>([])

  const getList = useCallback((loading = false) => {
    return http.post(url, {
      page: page.current,
      size: 20,
      hideLoading: loading
    })
      .then(({ data: { data } }) => {
        setLoading(data.list.length >= 20)
        backUp.current = [...backUp.current, ...data.list]
        setList([...backUp.current])
      })
  }, [url])

  useEffect(() => {
    getList()
  }, [getList])

  const loadMore = useCallback(() => {
    if (!showLoading) return Promise.resolve()
    page.current++
    return getList(true)
  }, [showLoading, getList])

  return (
    <ScrollWrap loadMore={loadMore}>
      {
        list.map((item) => {
          return (
            <IncomeItemWrap key={item.id}>
              <div className="left">
                <p>{type === '0' ? item.gift.title : item.plan.title}</p>
                <span>{item.create_time}</span>
              </div>
              <div className="diamond"><span>{item.coin_count}</span><img src={require('./zuanshi@2x.png')} alt=""/></div>
            </IncomeItemWrap>
          )
        })
      }
      {showLoading && <BottomLoading/>}
    </ScrollWrap>
  )
}

export default IncomeList