import React, { Fragment } from 'react'
// import { Icon } from 'antd-mobile'
// import trans from '../../common/util/px'
import HomeWrap from './style'
import { connect } from 'react-redux'
import { StateType } from '../../store/reducer'
import Recommend from '../recommend/recommend'
// import parseSearch from '../../common/util/parse-query'
// import LiveList from '../live-list/live-list'
import ReactDOM from "react-dom";
import TabBar from '../../components/tabbar/tabbar'

interface HomeProps {
  categories: any[],
  history: any
  location: any
}

const Home: React.FC<HomeProps> = ({ categories, history, location }) => {
  // const [page, setPage] = useState<string>(parseSearch(location.search).type || '0')

  // const tabs = [
  //   {
  //     title: <div>推荐</div>,
  //     key: '0'
  //   }
  // ]
  //
  // categories.forEach((item: any) => {
  //   tabs.push({
  //     title: <div>{ item.title }</div>,
  //     key: item.id.toString()
  //   })
  // })

  // const changeQuery = useCallback((type) => {
  //   // setPage(type)
  //   history.replace({
  //     pathname: '/',
  //     search: '?type=' + type
  //   })
  // }, [history])

  return (
    <Fragment>
      <HomeWrap>
        {/*<Tabs tabs={tabs}*/}
        {/*      page={page}*/}
        {/*      initialPage={parseSearch(location.search).type || '0'}*/}
        {/*      tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.2)'}}*/}
        {/*      tabBarActiveTextColor='#000'*/}
        {/*      prerenderingSiblingsNumber={0}*/}
        {/*      tabBarTextStyle={{ fontSize: trans(30), color: '#6F6F6F' }}*/}
        {/*      onChange={(tab, index) => { changeQuery(tab.key) }}*/}
        {/*>*/}
        {/*  {tabs.map((item) => <div className="content-wrap" key={item.key}>*/}
        {/*      {item.key === '0' ? <Recommend/> : <LiveList type={item.key} />}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</Tabs>*/}
        <Recommend/>
      </HomeWrap>
      {ReactDOM.createPortal(<TabBar/>, document.body)}
    </Fragment>
  )
}

const mapState = (state: StateType) => {
  return {
    categories: state.categories
  }
}

export default connect(mapState, null)(Home)
