import React, {useCallback, useState, useEffect} from 'react'
import ReactDOM from "react-dom";
import TabBar from '../../components/tabbar/tabbar'
import ScoreHomeWrap from './style'
import { Link, RouteChildrenProps } from 'react-router-dom'
import { Tabs } from 'antd-mobile'
import ScoreList from '../../components/score-list/score-list'
import parseSearch from '../../common/util/parse-query'
import './height.css'

const tabs = [
  {
    title: <div>热门</div>,
    key: '0'
  },{
    title: <div>赛程</div>,
    key: '2'
  },{
    title: <div>关注</div>,
    key: '3'
  },
]

const ScoreHome: React.FC<RouteChildrenProps> = ({ history, location }) => {
  const [page, setPage] = useState<string>(parseSearch(location.search).type)

  useEffect(() => {
    const { type } = parseSearch(location.search)
    setPage(type)
  }, [location])

  const changeQuery = useCallback((type) => {
    setPage(type)
    history.replace({
      pathname: '/score',
      search: '?type=' + type
    })
  }, [history])

  return (
    <ScoreHomeWrap>
      {page === '2' ? <Link className="screen" to={'/score/filter?type=' + page}><img src={require('./ic_shaixuan@2x.png')} alt=""/></Link> : null}
      <Tabs
        page={page}
        swipeable={false}
        animated={false}
        tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.2)'}}
        prerenderingSiblingsNumber={0}
        initialPage={page}
        onChange={(tab, index) => { changeQuery(tab.key) }}
        tabs={tabs}>
        {
          tabs.map((item) => {
            return <div className="content-wrap" key={item.key}>
              <ScoreList location={location} type={item.key} />
            </div>
          })
        }
      </Tabs>
      {ReactDOM.createPortal(<TabBar/>, document.body)}
    </ScoreHomeWrap>
  )
}

export default ScoreHome
