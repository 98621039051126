// @ts-ignore
import COS from 'cos-js-sdk-v5'
import http from './http'

function getCosData () {
  return http.post('/webapi/Config/getTempKeysForCos')
    .then(({ data }) => {
      return data.data
    })
}

export default () => new COS({
  getAuthorization: function (options: any, callback: any) {
    getCosData()
      .then(data => {
        const cosData = {
          TmpSecretId: data.credentials.tmpSecretId,
          TmpSecretKey: data.credentials.tmpSecretKey,
          XCosSecurityToken: data.credentials.sessionToken,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
          ScopeLimit: true
        }
        callback(cosData)
      })
  }
})
