import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import WithdrawRecordWrap from "./style";
import BackHeader from "../../components/back-header/back-header";
import { RouteChildrenProps } from 'react-router-dom'
import ScrollWrap from "../../components/scroll-wrap/scroll-wrap";
import http from '../../common/js/http'
import BottomLoading from "../../components/bottom-loading/bottom-loading";

const statusColor = ['#4EA4F5', '#40C843', '#F59C4E', '#FF1717']
const statusText = ['待处理', '已完成', '已驳回', '订单异常']

const WithdrawRecord: React.FC<RouteChildrenProps> = ({ history, location, match }) => {
  const [list, setList] = useState<any[]>([])
  const [showLoading, setLoading] = useState<any>(false)

  const page = useRef<number>(1)
  const backUp = useRef<any[]>([])

  const getList = useCallback((loading = false) => {
    return http.post('/webapi/withdraw/withdrawlog', {
      size: 10,
      page: page.current,
      hideLoading: loading
    })
      .then(({ data: { data } }) => {
        backUp.current = [...backUp.current, ...data.list]
        setList([...backUp.current])
        setLoading(data.list?.length >= 10)
      })
  }, [])

  useEffect(() => {
    getList()
  }, [getList])

  const loadMore = useCallback(() => {
    if (!showLoading) return Promise.resolve()
    page.current++
    return getList(true)
  }, [showLoading, getList])

  return (
    <Fragment>
      <BackHeader title='提现记录' history={history} location={location} match={match} />
      <WithdrawRecordWrap>
        <ScrollWrap loadMore={loadMore}>
          {
            list.map(item => {
              return (
                <div className='item' key={item.id}>
                  <div className="status">
                    <p>{item.create_time}</p>
                    <span style={{ background: statusColor[item.status] }}>{statusText[item.status]}</span>
                  </div>
                  <p>申请提现：{item.diamond}</p>
                  <p>收款支付宝：{item.alipay_account}</p>
                  <p>转账单号：{item.trade_no}</p>
                </div>
              )
            })
          }
          {showLoading && <BottomLoading />}
        </ScrollWrap>
      </WithdrawRecordWrap>
    </Fragment>
  )
}

export default WithdrawRecord
