import styled from 'styled-components'
import trans from '../../common/util/px'

const IncomeWrap = styled.div`
  height: calc(100vh - ${trans(90)} - 40px);
  overflow: hidden;
`

export const IncomeItemWrap = styled.div`
  margin-left: ${trans(30)};
  display: flex;
  height: ${trans(149)};
  align-items: center;
  justify-content: space-between;
  padding-right: ${trans(29)};
  .left {
    &>p {
      margin-bottom: ${trans(23)};
      font-size: ${trans(30)};
      color: #000000;
    }
    &>span {
      color: #969696;
      font-size: ${trans(24)};
    }
  }
  .diamond {
    display: flex;
    align-items: center;
    font-size: ${trans(30)};
    color: #000000;
    &>img {
      width: ${trans(33)};
      height: ${trans(28)};
      margin-left: ${trans(12)};
    }
  }
`

export default IncomeWrap