import React, { Fragment, useState, useCallback } from 'react'
import { Tabs, Icon } from 'antd-mobile'
import trans from '../../common/util/px'
import HomeWrap from './style'
import { connect } from 'react-redux'
import { StateType } from '../../store/reducer'
import parseSearch from '../../common/util/parse-query'
import LiveList from '../live-list/live-list'
import ReactDOM from "react-dom";
import TabBar from '../../components/tabbar/tabbar'
import '../score-home/height.css'

interface HomeProps {
  categories: any[],
  history: any
  location: any
}

const Home: React.FC<HomeProps> = ({ categories, history, location }) => {
  const [page, setPage] = useState<string>(parseSearch(location.search).type || '0')

  const tabs: any = [
    {
      title: <div>全部</div>,
      key: '0'
    }
  ]

  categories.forEach((item: any) => {
    tabs.push({
      title: <div>{ item.title }</div>,
      key: item.id.toString()
    })
  })

  const changeQuery = useCallback((type) => {
    setPage(type)
    history.replace({
      pathname: location.path,
      search: '?type=' + type
    })
  }, [history, location])

  return (
    <Fragment>
      <HomeWrap>
        <Tabs tabs={tabs}
              page={page}
              swipeable={false}
              animated={false}
              initialPage={parseSearch(location.search).type || '0'}
              tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.2)'}}
              tabBarActiveTextColor='#000'
              prerenderingSiblingsNumber={0}
              tabBarTextStyle={{ fontSize: trans(30), color: '#6F6F6F' }}
              onChange={(tab, index) => { changeQuery(tab.key) }}
        >
          {tabs.map((item: any) => <div className="content-wrap" key={item.key}>
              <LiveList type={item.key} />
            </div>
          )}
        </Tabs>
        <div className="icon-search" onClick={() => { history.push('/search') }}>
          <Icon type='search'/>
        </div>
      </HomeWrap>
      {ReactDOM.createPortal(<TabBar/>, document.body)}
    </Fragment>
  )
}

const mapState = (state: StateType) => {
  return {
    categories: state.categories
  }
}

export default connect(mapState, null)(Home)
