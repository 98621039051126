import styled from 'styled-components'
import trans from '../../common/util/px'
import {Link} from 'react-router-dom'

export const ScoreListWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .date-list {
    display: flex;
    height: ${trans(100)};
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${trans(28)};
    font-size: ${trans(20)};
    color: #969696;
    border-bottom: 1px solid #F4F4F4;
    background-color: #fff;
    .date-item {
      text-align: center;
      &>p:last-child {
        margin-top: ${trans(7)};
      }
      &.active {
        color: #F06E1E;
      }
    }
  }
  .list {
    height: 100%;
    overflow-y: scroll;
  }
`

export const ScoreItemWrap = styled(Link)`
  display: block;
  padding: ${trans(27)} ${trans(30)} ${trans(20)} ${trans(30)};
  border-bottom: ${trans(10)} solid #F4F4F4;
  position: relative;
  .collection {
    position: absolute;
    left: ${trans(27)};
    top: ${trans(83)};
    width: ${trans(36)};
    height: ${trans(36)};
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${trans(24)};
    color: #F06E1E;
    .name {
      flex: 1;
      display: flex;
      &>p {
        width: ${trans(109)};
      }
      &>span {
        color: #969696;
      }
    }
    .status {
      width: ${trans(96)};
      text-align: center;
    }
    .type {
      flex: 1;
      &>img {
        float: right;
        width: ${trans(47)};
        height: ${trans(34)};
      }
    }
  }
  .center {
    margin: ${trans(32)} ${trans(50)};
    display: flex;
    text-align: center;
    font-size: ${trans(30)};
    color: #000;
    &>span {
      &:nth-child(2) {
        margin: 0 ${trans(30)};
        color: #F06E1E;
      }
      &:first-child {
        flex: 1;
        text-align: right;
      }
      &:last-child {
        flex: 1;
        text-align: left;
      }
    }
  }
  .bottom {
    text-align: center;
    font-size: ${trans(24)};
    color: #969696;
    .red {
      display: inline-block;
      height: ${trans(30)};
      padding: ${trans(5)};
      margin-left: ${trans(12)};
      color: #fff;
      background-color: #FF1B1B;
      font-size: ${trans(20)};
    }
    .yellow {
      display: inline-block;
      height: ${trans(30)};
      padding: ${trans(5)};
      margin-left: ${trans(12)};
      color: #fff;
      background-color: #FF961B;
      font-size: ${trans(20)};
    }
  }
  .living {
     position: absolute;
     bottom: ${trans(23)};
     right: ${trans(29)};
     color: #FF2069;
     font-size: ${trans(22)};
     &.end {
        color: #969696;
     }
     &>img {
        vertical-align: bottom;
        margin-right: ${trans(10)};
        width: ${trans(20)};
        height: ${trans(22)};
     }
  }
  .avatars {
    display: flex;
    justify-content: center;
    &>img {
      margin-right: ${trans(8)};
      margin-top: ${trans(20)};
      width: ${trans(44)};
      height: ${trans(44)};
      border-radius: 50%;
    }
  }
`
