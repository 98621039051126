import styled from 'styled-components'
import trans from '../../common/util/px'

const AnchorFocusWrap = styled.div`
  .list {
    width: 100%;
    height: calc(100vh - ${trans(90)});
    overflow: hidden;
    .anchor {
      padding: 0 ${trans(30)};
      display: flex;
      align-items: center;
      margin-top: ${trans(42)};
      &>img {
        width: ${trans(85)};
        height: ${trans(85)};
        margin-right: ${trans(16)};
        border-radius: 50%;
      }
      .center {
        flex: 1;
        &>h4 {
          margin-bottom: ${trans(14)};
          font-size: ${trans(30)};
          color: #000;
        }
        &>p {
          font-size: ${trans(24)};
          color: #7F7F7F;
        }
      }
      &>button {
        padding: 0 ${trans(15)};
        height: ${trans(40)};
        border-radius: ${trans(20)};
        font-size: ${trans(24)};
        color: #FFFFFF;
        background: linear-gradient(81deg,rgba(255,129,33,1),rgba(255,197,42,1));
        border: none;
        outline: none;
        &.active {
          opacity: 0.6;
        }
      }
    }
  }
`

export default AnchorFocusWrap