import styled from 'styled-components'
import trans from '../../common/util/px'

const TaskWrapper = styled.div`
  width: 100%;
  .sign {
    width: 100%;
    height: ${trans(710)};
    box-sizing: border-box;
    padding-top: ${trans(125)};
    background: url(${require('./mission_bg@2x.png')}) center center no-repeat;
    background-size: cover;
    position: relative;
    .back {
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
    }
    .circle {
      width: ${trans(537)};
      height: ${trans(537)};
      margin: 0 auto;
      box-sizing: border-box;
      background-size: 100% 100%;
      padding-top: ${trans(211)};
      text-align: center;
      &>p {
        margin-bottom: ${trans(44)};
        font-size: ${trans(26)};
        color: #FFFFFF;
      }
      &>span {
        font-weight: bold;
        font-size: ${trans(60)};
        color: #fff;
        &:after {
          font-weight: 400;
          margin-left: ${trans(29)};
          content: '天';
          font-size: ${trans(26)};
        }
      }
    }
    .btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: ${trans(598)};
      width: ${trans(239)};
      height: ${trans(72)};
      line-height: ${trans(72)};
      text-align: center;
      border-radius: ${trans(36)};
      background: #fff;
      font-size: ${trans(32)};
      color: #F1701F;
    }
  }
  .sub-title {
    padding: ${trans(33)} 0 ${trans(64)} ${trans(30)};
    font-size: ${trans(32)};
    color: #333333;
  }
  .video {
    padding: 0 ${trans(30)};
    display: flex;
    align-items: center;
    margin-bottom: ${trans(83)};
    &>img {
      margin-right: ${trans(19)};
      width: ${trans(71)};
      height: ${trans(69)};
    }
    .right {
      flex: 1;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &>p {
          font-size: ${trans(32)};
          color: #333333;
          &>span {
            color: #F1701F;
          }
        }
        .btn {
          width: ${trans(129)};
          height: ${trans(55)};
          line-height: ${trans(55)};
          border-radius: ${trans(27)};
          text-align: center;
          font-size: ${trans(26)};
          color: #F1701F;
          border: 1px solid #F1701F;
          &.active {
            background: #F1701F;
            color: #fff;
          }
        }
      }
      .percent {
        display: flex;
        padding-top: ${trans(53)};
        .hour-percent {
          width: ${trans(50)};
          height: ${trans(11)};
          box-sizing: border-box;
          border-top: 1px solid #F1701F;
          border-bottom: 1px solid #F1701F;
          position: relative;
          margin-left: ${trans(5)};
          &.half {
            width: ${trans(26)};
            border: 1px solid #F1701F;
            border-radius: ${trans(6)};
          }
          .bg {
            height: 100%;
            background: #F1701F;
          }
          &>img {
            position: absolute;
            right: -${trans(2)};
            transform: translate3d(50%, -50%, 0);
            width: ${trans(15)};
            height: ${trans(15)};
            top: 50%;
          }
          &>span {
            position: absolute;
            font-size: ${trans(18)};
            color: #979797;
            right: 0;
            transform: translate3d(50%, -50%, 0);
          }
          .mi {
            top: -${trans(21)};
          }
          .hour {
            top: ${trans(21)};
          }
        }
      }
    }
  }
  .gift {
    display: flex;
    align-items: center;
    padding: 0 ${trans(30)};
    padding-bottom: ${trans(86)};
    .icon {
      margin-right: ${trans(21)};
      width: ${trans(69)};
      height: ${trans(69)};
    }
    .right {
      flex: 1;
      &>h6 {
        margin-bottom: ${trans(14)};
        font-size: ${trans(32)};
        color: #333333;
        &>span {
          color: #F1701F;
        }
      }
      &>span {
        font-size: ${trans(26)};
        color: #939393;
      }
    }
    .btn {
      width: ${trans(129)};
      height: ${trans(55)};
    }
  }
  .mi-alert {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      padding-top: ${trans(110)};
      width: ${trans(477)};
      border-radius: ${trans(36)};
      background: #fff;
      position: relative;
      padding-bottom: ${trans(44)};
      &>p {
        text-align: center;
        font-size: ${trans(36)};
        color: #2A2A2A;
        &>span {
          font-size: ${trans(48)};
          color: #F46F22;
        }
      }
      .btn {
        width: ${trans(213)};
        margin: 0 auto;
        margin-top: ${trans(69)};
        height: ${trans(70)};
        line-height: ${trans(70)};
        border-radius: ${trans(35)};
        background: #F46F22;
        text-align: center;
        color: #fff;
      }
      .close {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: ${trans(51)};
        height: ${trans(51)};
        bottom: -${trans(136)};
      }
      .title {
        position: absolute;
        width: ${trans(366)};
        height: ${trans(203)};
        top: -${trans(156)};
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`

export default TaskWrapper
