import styled from 'styled-components'
import trans from '../../common/util/px'

const HappeningWrap = styled.div`
  min-height: 100%;
  background-color:  #F4F4F4;
  .head {
    padding-top: ${trans(19)};
    display: flex;
    align-items: center;
    background-color: #fff;
    .team {
      flex: 1;
      display: flex;
      align-items: center;
      &>p {
        font-size: ${trans(24)};
        color: #333333;
      }
      &>img {
        width: ${trans(38)};
        height: ${trans(38)};
        margin-left: ${trans(13)};
      }
      &:first-child {
        justify-content: flex-end;
      }
      &:last-child {
        justify-content: flex-start;
      }
    }
    &>span {
      margin: 0 ${trans(60)};
      font-size: ${trans(24)};
      color: #333333;
    }
  }
  .percent {
    overflow: hidden;
    padding-bottom: ${trans(36)};
    background-color: #fff;
  }
  .msg-list {
    padding: ${trans(30)} ${trans(22)};
    background-color: #F4F4F4;
    &>h4 {
      margin-bottom: ${trans(11)};
      font-size: ${trans(30)};
      color: #333333;
    }
    &>p {
      display: flex;
      margin-top: ${trans(20)};
      align-items: center;
      &>img {
        width: ${trans(52)};
        height: ${trans(52)};
      }
      &>span {
        flex: 1;
        height: ${trans(72)};
        line-height: ${trans(72)};
        padding: 0 ${trans(20)};
        background-color: #fff;
      }
    }
  }
`

export const PercentWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${trans(60)};
  margin-top: ${trans(38)};
  &>p {
    flex: 1;
    font-size: ${trans(24)};
    color: #666666;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
    &:nth-child(3) {
      flex: none;
      text-align: center;
       width: ${trans(106)};
    }
  }
  .progress {
    width: ${trans(198)};
    height: ${trans(14)};
    border-radius: ${trans(7)};
    overflow: hidden;
    background-color: #ECECEC;
    &.left-percent {
      margin-left: ${trans(13)};
      .active {
        height: 100%;
        background-color: #FFD156;
      }
    }
    &.right-percent {
      margin-right: ${trans(13)};
      .active {
        height: 100%;
        background-color: #71CFFF;
      }
    }
  }
`

export default HappeningWrap