import styled from 'styled-components'
import trans from '../../common/util/px'

const EditAccountWrap = styled.div`
  height: 100vh;
  position: relative;
  .input {
    height: ${trans(111)};
    display: flex;
    align-items: center;
    margin-left: ${trans(30)};
    padding-right: ${trans(32)};
    border-bottom: 1px solid #F3F3F3;
    &>label {
      width: ${trans(150)};
      font-size: ${trans(30)};
      color: #656565;
    }
    &>input {
      flex: 1;
      box-sizing: border-box;
      font-size: ${trans(30)};
      outline: none;
      border: none;
      text-align: right;
      background: transparent;
    }
    &>button {
      width: ${trans(160)};
      height: ${trans(55)};
      border-radius: ${trans(27)};
      background-color: #EF8B1D;
      outline: none;
      border: none;
      font-size: ${trans(20)};
      color: #fff;
      margin-left: ${trans(43)};
    }
  }
  .submit {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${trans(96)};
    width: ${trans(625)};
    min-width: ${trans(625)};
    height: ${trans(100)};
    border-radius: ${trans(50)};
    background-color: #EF8B1D;
    outline: none;
    border: none;
    color: #fff;
    font-size: ${trans(32)};
  }
`

export default EditAccountWrap