import styled from 'styled-components'
import trans from '../../common/util/px'

const MyMaterialWrap = styled.div`
  height: 100vh;
  overflow: hidden;
  .content {
    height: calc(100vh - ${trans(90)} - 40px);
    overflow: hidden;
  }
`

export default MyMaterialWrap