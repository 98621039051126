import { AnyAction } from 'redux'
import Constants from './constants'
import { getItem } from "../common/js/storage";

export interface StateType {
  sdkConfig: any
  categories: Array<any>
  userInfo: any
  tim: any
}

const initState: StateType = {
  sdkConfig: {},
  categories: getItem('categories') || [],
  userInfo: {
    fans_count: 0,
    attent_count: 0
  },
  tim: null
}

export default (state: StateType = initState, action: AnyAction) => {
  const { type, payload } = action
  switch (type) {
    case Constants.InitConfig:
      return {
        ...state,
        sdkConfig: payload
      }
    case Constants.SetCategories:
      return {
        ...state,
        categories: payload
      }
    case Constants.SetUserInfo:
      return {
        ...state,
        userInfo: payload
      }
    case Constants.SetIm:
      return {
        ...state,
        tim: payload
      }
    default:
      return state
  }
}
