import styled from 'styled-components'
import trans from '../../common/util/px'

const TabBarWrap = styled.div`
  width: 100%;
  height: ${trans(96)};
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-top: 1px solid #F4F4F4;
  &>a {
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: #939393;
    &>img {
      width: ${trans(58)};
      height: ${trans(58)};
      padding-bottom: ${trans(5)};
      display: none;
      &.gray {
        display: inline;
      }
      &.orange {
        display: none;
      }
    }
    &>p {
      font-size: ${trans(24)};
    }
    &.active {
      color: #F06E1E;
      .gray {
        display: none;
      }
      .orange {
        display: inline;
      }
    }
  }
`

export default TabBarWrap