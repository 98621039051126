import axios from 'axios'
import { Toast, Modal } from 'antd-mobile'
import store from '../../store'
import { SetUserInfo } from "../../store/action";

const alert = Modal.alert

export const host = ''

const instance = axios.create({
  baseURL: host,
  timeout: 10000
})

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    const uid = localStorage.getItem('uid')
    if (typeof config.data !== 'string') {
      if (!config.data) config.data = {}
      if (token && config.data) {
        config.data.token = token
      }
      if (uid && config.data) {
        config.data.uid = uid
      }
    }
    if (!config.data.hideLoading) {
      Toast.loading('加载中...', 20)
    }
    delete config.data.hideLoading
    return config
  },
  err => {
    return Promise.reject(err)
  })

instance.interceptors.response.use((res) => {
  Toast.hide()
  if (res.data.msg === '登录超时，请重新登录') {
    store.dispatch(SetUserInfo({}))
    localStorage.clear()
    if (res.config.url !== '/api/User/getUserInfo') {
      alert('您还未登录', '请登录使用完整功能', [
        {text: '取消', onPress: () => {}},
        {text: '确定', onPress: () => { window.location.href = '/login' }}
      ])
      return Promise.reject('登陆超时')
    } else {
      return Promise.reject('登陆超时')
    }
    // Toast.fail(res.data.msg, 1.5, undefined, true)
    // return Promise.reject('登陆超时')
  }
  if (res.data.msg) {
    Toast.info(res.data.msg)
    return Promise.reject('操作有误')
  }
  return res
}, (err) => {
  Toast.hide()
  Toast.fail('请求超时，请稍后再试', 1.5)
  return Promise.reject(err)
})

export default instance
