import styled from 'styled-components'
import trans from '../../common/util/px'

const HomeWrap = styled.div`
  width: 100%;
  padding-bottom: ${trans(96)};
  .am-tabs-default-bar {
    width: 88vw;
    overflow: hidden;
  }
`

export default HomeWrap
