import React, { Fragment } from "react";
import BackHeader from '../../components/back-header/back-header'
import { RouteChildrenProps } from 'react-router-dom'
import parseSearch from "../../common/util/parse-query";
import trans from "../../common/util/px";
import {Tabs} from "antd-mobile";
import IncomeWrap from "./style";
import IncomeList from "./list";

const tabs = [
  {
    title: <div>礼物收益</div>,
    key: '0'
  },
  {
    title: <div>有料收益</div>,
    key: '1'
  }
]

const Income: React.FC<RouteChildrenProps> = ({ history,location, match }) => {
  return (
    <Fragment>
      <BackHeader title='收益记录' history={history} location={location} match={match} />
      <Tabs tabs={tabs}
            initialPage={parseSearch(location.search).type || '0'}
            tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.1)'}}
            prerenderingSiblingsNumber={0}
            tabBarTextStyle={{ fontSize: trans(30) }}
            // onChange={(tab, index) => { changeQuery(tab.key) }}
      >
        <IncomeWrap key='0'>
          <IncomeList type='0' />
        </IncomeWrap>
        <IncomeWrap key='1'>
          <IncomeList type='1' />
        </IncomeWrap>
      </Tabs>
    </Fragment>
  )
}

export default Income