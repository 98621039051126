import React from 'react'
import { Switch, Route } from 'react-router-dom'
import My from '../pages/my/my'
import UserSetting from "../pages/user-setting/user-setting";
import AnchorFocus from "../pages/anchor-focus/anchor-focus";
import UserInfo from "../pages/user-info/user-info";
import BindPhone from "../pages/bind-phone/bind-phone";
import SysMessage from '../pages/sys-message/sys-message'
import Wallet from "../pages/wallet/wallet";
import WithdrawRecord from "../pages/withdraw-record/withdraw-record";
import EditAccount from "../pages/edit-account/edit-account";
import Income from "../pages/income/income";
import LiveRecord from "../pages/live-record/live-record";
import MyMaterial from "../pages/my-material/my-material";
import ApplyForm from "../pages/apply-form/apply-form";
import Task from "../pages/task/task";

const UserCenter = () => {
  return (
    <Switch>
      <Route exact path='/user-center' component={My} />
      <Route exact path='/user-center/setting' component={UserSetting} />
      <Route exact path='/user-center/focus' component={AnchorFocus} />
      <Route exact path='/user-center/info' component={UserInfo} />
      <Route exact path='/user-center/bind-phone' component={BindPhone} />
      <Route exact path='/user-center/message' component={SysMessage} />
      <Route exact path='/user-center/wallet' component={Wallet} />
      <Route exact path='/user-center/withdraw' component={WithdrawRecord} />
      <Route exact path='/user-center/edit-account' component={EditAccount} />
      <Route exact path='/user-center/income' component={Income} />
      <Route exact path='/user-center/live-record' component={LiveRecord} />
      <Route exact path='/user-center/my-material' component={MyMaterial} />
      <Route exact path='/user-center/apply/:type' component={ApplyForm} />
      <Route exact path='/user-center/task' component={Task} />
    </Switch>
  )
}

export default UserCenter
