import React, { useEffect } from 'react'
import { Icon } from 'antd-mobile'

let observer: any = null

interface LoadingProps {
  loadMore?(): void
}

const BottomLoading: React.FC<LoadingProps> = ({ loadMore }) => {
  useEffect(() => {
    const loadingEl = document.getElementById('bottom_loading')
    if (loadingEl) {
      observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          loadMore && loadMore()
        }
      })
      observer.observe(loadingEl)
    }

    return () => {
      if (observer && loadingEl) {
        observer.unobserve(loadingEl)
        observer.disconnect()
      }
    }
  }, [loadMore])

  return (
    <div id="bottom_loading" style={{ height: '20px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
      <Icon type='loading' size='xs' />
    </div>
  )
}

export default BottomLoading
