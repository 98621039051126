import React, { Fragment, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import Home from './pages/home/home'
import Score from './router/score'
// import Material from './router/material'
import UserCenter from './router/user-center'
import Login from './pages/login/login'
import Register from './pages/register/register'
import LiveDetail from './pages/live-detail/live-detail'
import {getConfig, getUserInfo} from './store/action'
import Search from "./pages/search/search";
import Anchor from './pages/anchor/anchor'

function App({ initConfig, getUserInfo }: any) {
  useEffect(() => {
    initConfig()
    getUserInfo()
  }, [initConfig, getUserInfo])

  return (
    <Fragment>
      <Route exact path='/' component={Home} />
      <Route exact path='/search' component={Search} />
      <Route path='/score' component={Score} />
      <Route path='/anchor' component={Anchor} />
      <Route path='/user-center' component={UserCenter} />
      <Route path='/login' component={Login} />
      <Route path='/register' component={Register} />
      <Route path='/forget-password' component={Register} />
      <Route path='/live-detail/:id' component={LiveDetail} />
    </Fragment>
  );
}

const mapDispatch = (dispatch: Dispatch) => {
  return {
    initConfig() {
      dispatch<any>(getConfig())
    },
    getUserInfo() {
      dispatch<any>(getUserInfo())
    }
  }
}

export default connect(null, mapDispatch)(App)
