import React, {useCallback, useEffect, useRef, useState} from 'react'
import RaceDetailWrap from './style'
import http from '../../common/js/http'
import {Link, RouteChildrenProps} from "react-router-dom";
import RaceDesc from './race-desc'
import { Tabs } from 'antd-mobile'
import RaceHappening from '../../components/race-happening/race-happening'
import Message from '../../components/message/message'
// import RaceMaterial from '../../components/race-material/race-material'
import DPlayer from 'dplayer'
import RaceAnchor from './race-anchor'
import RaceIndex from "./race-index";
import '../score-home/height.css'

const tabs = [
  {
    key: '3',
    title: <div>主播</div>
  },
  {
    key: '0',
    title: <div>赛况</div>
  },{
    key: '1',
    title: <div>聊天</div>
  },{
    key: '2',
    title: <div>指数</div>
  }
]

const RaceDetail: React.FC<RouteChildrenProps> = ({ match , history}) => {
  const [detail, setDetail] = useState<any>({})

  const player = useRef<DPlayer>()

  const createPlayer = useCallback((url) => {
    player.current = new DPlayer({
      container: document.getElementById('player'),
      video: {
        url: url.replace('.flv', '.m3u8')
      },
      autoplay: true,
      loop: true
    })
  }, [])

  const getDetail = useCallback(() => {
    const { id } = match!.params as { id: string }
    http.post('/api/football/getMatchInfo', {
      matchid: id
    })
      .then(({ data: { data } }) => {
        setDetail(data)
        if (data.live) {
          const url: any = data.live.pushurl1 || data.live.pushurl2 || data.live.pushurl3
          createPlayer(url.replace('rtmp', 'https') + '.m3u8')
        }
      })
  }, [match, createPlayer])

  useEffect(() => {
    getDetail()
    // eslint-disable-next-line
  }, [])

  const collection = useCallback(() => {
    http.post('/api/Football/attentMatch', {
      matchid: detail.id,
      type: Number(!detail.attented)
    })
      .then(() => {
        const copy = {...detail}
        copy.attented = Number(!copy.attented)
        setDetail(copy)
      })
  }, [detail])

  return (
    <RaceDetailWrap>
      <div className="animation-wrap">
        {!detail.live && <RaceDesc collection={collection} history={history} desc={detail} />}
        <div className="player" id="player"></div>
      </div>
      <Tabs
        swipeable={false}
        animated={false}
        tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.2)'}}
        prerenderingSiblingsNumber={0}
        initialPage={'3'}
        tabs={tabs}>
        <div className="content" key='0'>
          {detail.id ? <RaceHappening desc={detail} /> : null}
        </div>
        <div className="content" key='1'>
          {detail.id ? <Message type='avc' roomId={'matchroom_' + (match!.params as { id: string }).id} /> : null}
        </div>
        <div className="content" key='2'>
          <RaceIndex raceId={(match!.params as { id: string }).id}/>
        </div>
        <div className="content" key='3'>
          <RaceAnchor matchId={(match!.params as { id: string }).id} />
        </div>
      </Tabs>
      <Link to="/" className="go-home">首页</Link>
    </RaceDetailWrap>
  )
}

export default RaceDetail
