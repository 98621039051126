import styled from 'styled-components'
import trans from '../../common/util/px'

const BackHeaderWrap = styled.div`
  height: ${trans(90)};
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  font-size: ${trans(36)};
  &>span {
    flex: 1;
    padding-right: ${trans(72)};
    text-align: center;
    font-size: ${trans(36)};
    color: #060606;
  }
`

export default BackHeaderWrap