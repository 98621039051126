import React, {Fragment, useCallback, useRef, useState} from "react";
import { connect } from "react-redux";
import zhengmian from './zhengmian@2x.png'
import fanmian from './fanmian@2x.png'
import shouchi from './shouchi@2x.png'
import {Toast} from "antd-mobile";
import cos from "../../common/js/upload";
import http from '../../common/js/http'

interface VerifiedProps {
  next(step: number): void
  userInfo?: any
  sdkConfig?: any
}

interface FormTypes {
  real_name: string
  id_num: string
  alipay_account: string
  alipay_name: string
}

const Verified: React.FC<VerifiedProps> = ({ next, userInfo, sdkConfig }) => {
  const [form, setForm] = useState<FormTypes>({
    real_name: '',
    id_num: '',
    alipay_account: '',
    alipay_name: ''
  })

  const [imgs, setImgs] = useState<string[]>(['', '', ''])

  const imgType = useRef<number>(0)

  const fileRef = useRef<any>()

  const inputChange = useCallback((e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }, [form])

  const clickFile = useCallback((e: any) => {
    imgType.current = Number(e.currentTarget.dataset.type)
    fileRef.current.click()
  }, [])

  const upload = useCallback(() => {
    const file = fileRef.current.files[0]
    if (file.type.indexOf('image') === -1) {
      Toast.fail('请选择图片文件')
      return
    }
    const cosObj = cos()
    cosObj.putObject({
      Bucket: sdkConfig.cos_bucket,
      Region: sdkConfig.cos_region,
      Key: sdkConfig.cos_folder_image + `/${file.name}`,
      Body: file
    }, (err: any, data: any) => {
      if (err) {
        console.log(err)
        Toast.fail('上传失败')
        return
      }
      const copy = [...imgs]
      copy[imgType.current] = 'https://' + data.Location
      setImgs(copy)
      fileRef.current.value = ''
    })
  }, [sdkConfig, imgs])

  const submit = useCallback(() => {
    if (userInfo.is_anchor || userInfo.is_youliao) {
      next(3)
      return
    }
    let msg = ''
    if (!form.real_name) msg = '请输入真实姓名'
    if (!form.id_num && !msg) msg = '请输入身份证号'
    if (!form.alipay_name && !msg) msg = '请输入支付宝姓名'
    if (!form.alipay_account && !msg) msg = '请输入支付宝账户'
    if (!msg && imgs.some(item => !item)) msg = '请上传身份信息'
    if (msg) {
      Toast.fail(msg, 1, undefined, false)
      return
    }
    http.post('/api/auth/identityAuth', {
      ...form,
      id_card_url: imgs.map(item => {return item.replace('https://', '')}).join(',')
    })
      .then(() => {
        next(3)
      })
  }, [form, imgs, userInfo, next])
  
  return (
    <div className="verified">
      {
        userInfo.is_anchor || userInfo.is_youliao ? <div className='is-end'>
          <img src={require('./bg_renzheng@2x.png')} alt=""/>
          <p>您的账号已认证，可直接进入下一步。</p>
        </div> : <Fragment>
          <div className="input">
            <label>真实姓名</label>
            <input name='real_name' value={form.real_name} onChange={inputChange} type="text" placeholder='请输入真实姓名'/>
          </div>
          <div className="input">
            <label>身份证号</label>
            <input name='id_num' value={form.id_num} onChange={inputChange} type="text" placeholder='请输入身份证号'/>
          </div>
          <div className="input">
            <label>支付宝姓名</label>
            <input name='alipay_name' value={form.alipay_name} onChange={inputChange} type="text" placeholder='请输入支付宝姓名'/>
          </div>
          <div className="input">
            <label>支付宝账号</label>
            <input name='alipay_account' value={form.alipay_account} onChange={inputChange} type="text" placeholder='请输入支付宝账号'/>
          </div>
          <div className="upload">
            <h4><i/>身份认证</h4>
            <div className="card">
              <div data-type='0' onClick={clickFile} className="item">
                <img src={imgs[0] || zhengmian} alt=""/>
                <p>身份证正面</p>
              </div>
              <div data-type='1' onClick={clickFile} className="item">
                <img src={imgs[1] || fanmian} alt=""/>
                <p>身份证背面</p>
              </div>
              <div data-type='2' onClick={clickFile} className="item">
                <img src={imgs[2] || shouchi} alt=""/>
                <p>手持身份证</p>
              </div>
            </div>
          </div>
          <p className="tips"><span>温馨提示：</span>
            请确保姓名、身份证号清晰可见，这可以帮助客服人
            员快速通过你的申请
          </p>
        </Fragment>
      }
      <button onClick={submit}>下一步</button>
      <input onChange={upload} ref={fileRef} type="file" style={{ display: 'none' }}/>
    </div>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo,
  sdkConfig: state.sdkConfig
})

export default connect(mapState, null)(Verified)