import styled from 'styled-components'
import trans from '../../common/util/px'

const DetailRankWrap = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-left: ${trans(32)};
  overflow-y: scroll;
  .tabs {
    display: flex;
    box-sizing: border-box;
    width: ${trans(442)};
    margin: 0 auto;
    margin-top: ${trans(42)};
    height: ${trans(62)};
    line-height: ${trans(58)};
    border: 1px solid #FF8A43;
    margin-bottom: ${trans(54)};
    &>p {
      flex: 1;
      height: 100%;
      text-align: center;
      font-size: ${trans(30)};
      color: #FF8A43;
      &.active {
        background-color: #FF8A43;
        color: #fff;
      }
    }
  }
  .rank-item {
    display: flex;
    align-items: center;
    margin-bottom: ${trans(42)};
    .rank-icon {
      width: ${trans(64)};
      height: ${trans(74)};
      margin-right: ${trans(35)};
    }
    .rank-num {
      width: ${trans(97)};
      font-size: ${trans(28)};
      color: #3A3A3A;
    }
    .avatar {
      width: ${trans(85)};
      height: ${trans(85)};
      margin-right: ${trans(17)};
      border-radius: 50%;
    }
    .right {
      .top {
        margin-bottom: ${trans(18)};
        display: flex;
        align-items: center;
        font-size: ${trans(30)};
        color: #000000;
        &>img {
          width: ${trans(42)};
          height: ${trans(26)};
          margin-left: ${trans(17)};
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        font-size: ${trans(24)};
        color: #7F7F7F;
        &>img {
          width: ${trans(25)};
          height: ${trans(21)};
          margin-right: ${trans(10)};
        }
      }
    }
  }
`

export default DetailRankWrap