import React, {useCallback, useEffect, useRef, useState} from 'react'
import MessageWrap from './style'
import { connect } from 'react-redux'
import LevelIcon from '../../common/js/level-icon'
// @ts-ignore
import TIM from 'tim-js-sdk'
import {Toast, Modal} from "antd-mobile";
import {setItem} from "../../common/js/storage";
import { getDate } from "../../common/util/transtime";

let sendTimer: any = null

const alert = Modal.alert

interface MessageProps {
  roomId: string
  sdkConfig?: any
  type?: string
  userInfo?: any
  isAttend?: boolean
}

const Message: React.FC<MessageProps> = ({ roomId, sdkConfig, type = 'video', userInfo, isAttend }) => {
  const [msgs, setMsg] = useState<any[]>([])
  const [inputMsg, setInput] = useState<string>('')

  const tim = useRef<any>()
  const copyMsgs = useRef<any[]>([])
  const scroll = useRef<any>()
  const input = useRef<any>()

  const showMsg = useCallback((data: any) => {
    copyMsgs.current = [...copyMsgs.current, data]
    setMsg(copyMsgs.current)
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight
    }
  }, [copyMsgs])

  const sendMsg = useCallback((message: string, action: string) => {
    const content = {
      Action: action,
      Data: {
        chat: {
          sender: {
            id: userInfo.id,
            nick_name: userInfo.nick_name,
            avatar: userInfo.avatar,
            user_level: userInfo.user_level ? userInfo.user_level.toString() : '1',
            vip_level: userInfo.vip_level,
            vip_date: userInfo.vip_date,
            is_anchor: userInfo.is_anchor
          },
          message: message
        }
      }
    }
    const msgBody: any = tim.current.createCustomMessage({
      to: roomId,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data: JSON.stringify(content)
      }
    })

    tim.current.sendMessage(msgBody)
      .then(() => {
        showMsg(content)
        setInput('')
      })
      .catch((e: Error) => {
        Toast.fail(e.message)
      })
  }, [showMsg, userInfo, roomId, setInput])

  const receiveMsg = useCallback((e) => {
    e.data.forEach((item: any) => {
      if (!item.payload.data) return
      const data = JSON.parse(item.payload.data)
      // if (data.Action === 'LiveFinished') this.$emit('liveFinished')
      if ((data.Action === 'RoomMessage' || data.Action === 'RoomAttentAnchor') && item.to === roomId) {
        if (data.Data.chat.sender.id === userInfo.id) {
          return
        }
        showMsg(data)
      }
      if (data.Action === 'RoomAttentAnchor') {
        showMsg(data)
        // this.$emit('increaseAttend')
      }
      // if (data.Action === 'LiveGroupMemberJoinExit') getMemberNum()
      // if (data.Action === 'GiftAnimation') this.$emit('pushGift', data)
    })
  }, [showMsg, roomId, userInfo])

  const ready = useCallback(() => {
    console.log('ready>>>>>>>>>')
    // if (this.type === 'video') this.getMemberNum()
  }, [])

  useEffect(() => {
    console.log(roomId)
    if (!roomId) return
    tim.current = TIM.create({
      SDKAppID: sdkConfig.im_sdkappid
    })

    tim.current.setLogLevel(3)

    tim.current.off(TIM.EVENT.MESSAGE_RECEIVED, receiveMsg)
    tim.current.off(TIM.EVENT.SDK_READY, ready)

    tim.current.on(TIM.EVENT.MESSAGE_RECEIVED, receiveMsg)

    tim.current.on(TIM.EVENT.SDK_READY, ready)

    if (userInfo.id) {
      tim.current.login({ userID: userInfo.id.toString(), userSig: userInfo.txim_sign })
        .then(() => {
          tim.current.joinGroup({ groupID: roomId })
            .then(() => {
              if (userInfo.id) {
                clearTimeout(sendTimer)
                // this.joinMsg = '加入直播间'
                sendTimer = setTimeout(() => {
                  sendMsg('进入直播间', 'RoomMessage')
                }, 500)
              }
            })
            .catch((e: any) => {
              Toast.fail(e.message)
            })
        })
    }

    return () => {
      if (tim.current) {
        tim.current.off(TIM.EVENT.MESSAGE_RECEIVED, receiveMsg)
        tim.current.off(TIM.EVENT.SDK_READY, ready)
        tim.current.logout()
        if (type !== 'avc') {
          tim.current.quitGroup(roomId)
        }
      }
    }
  }, [roomId, userInfo, receiveMsg, ready, sdkConfig, type, sendMsg])

  const changeInput = useCallback(() => {
    setInput(input.current.value)
  }, [input])

  const submit = useCallback((e) => {
    e.preventDefault()
    if (!userInfo.id) {
      alert('您还未登录', '请登录使用完整功能', [
        {text: '取消', onPress: () => {}},
        {text: '确定', onPress: () => { window.location.href = '/login' }}
      ])
      return
    }
    if (!inputMsg) {
      Toast.fail('请先输入消息')
      return
    }
    sendMsg(inputMsg, 'RoomMessage')
    setItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}danmu`, '1')
  }, [userInfo.id, sendMsg, inputMsg])

  useEffect(() => {
    if (isAttend) {
      sendMsg('关注了主播', 'RoomAttentAnchor')
    }
  }, [isAttend, sendMsg])

  return (
    <MessageWrap>
      <div ref={scroll} className="scroll">
        <p className="tips">{sdkConfig.room_notice}</p>
        {
          msgs.map((msg, index) => {
            return <p className='msg-item' key={index}><img src={LevelIcon[msg.Data.chat.sender.user_level]} alt=""/><span>{msg.Data.chat.sender.nick_name}：</span>{msg.Data.chat.message}</p>
          })
        }
      </div>
      <form className="form" onSubmit={submit}>
        <input value={inputMsg} onChange={changeInput} ref={input} type="text"/>
        <button type='submit'>发送</button>
      </form>
    </MessageWrap>
  )
}

const mapState = (state: any) => ({
  sdkConfig: state.sdkConfig,
  userInfo: state.userInfo
})

export default connect(mapState, null)(Message)
