import styled from 'styled-components'
import trans from '../../common/util/px'
import WalletBg from "./wallet-bg@2x.png";

const WalletWrap = styled.div`
  padding-bottom: ${trans(40)};
  .head {
    height: ${trans(90)};
    padding-right: ${trans(30)};
    display: flex;
    align-items: center;
    &>p {
      flex: 1;
      padding-left: ${trans(220)};
      font-size: ${trans(34)};
      color: #070707;
    }
    &>a {
      font-size: ${trans(30)};
      color: #070707;
    }
  }
  .nums {
    width: ${trans(690)};
    margin: 0 auto;
    margin-top: ${trans(50)};
    height: ${trans(259)};
    display: flex;
    align-items: center;
    background: url(${WalletBg}) center center no-repeat;
    background-size: cover;
    border-radius: ${trans(20)};
    &>i {
      width: 1px;
      height: ${trans(117)};
      background: #fff;
    }
    .num {
      flex: 1;
      text-align: center;
      color: #fff;
      &>span {
        font-size: ${trans(30)};
      }
      &>p {
        margin-top: ${trans(25)};
        font-size: ${trans(40)};
        font-weight: bold;
      }
    }
  }
  .input {
    display: flex;
    align-items: center;
    margin: 0 ${trans(30)};
    height: ${trans(110)};
    border-bottom: 1px solid #F4F4F4;
    &>label {
      font-size: ${trans(32)};
      color: #333333;
    }
    &>input {
      flex: 1;
      padding-left: ${trans(60)};
      border: none;
      outline: none;
      font-size: ${trans(32)};
    }
  }
  .out-money {
    display: flex;
    height: ${trans(130)};
    align-items: center;
    padding: 0 ${trans(30)};
    font-size: ${trans(32)};
    color: #333333;
    border-bottom: ${trans(15)} solid #F4F4F4;
    &>span {
      margin-left: ${trans(31)};
      font-size: ${trans(32)};
      font-weight: bold;
      color: #F08B1E;
    }
  }
  .account {
    padding: ${trans(62)} ${trans(30)} ${trans(78)} ${trans(30)};
    .sub-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${trans(56)};
      &>h4 {
        font-size: ${trans(32)};
        color: #333333;
      }
      .link {
        width: ${trans(138)};
        height: ${trans(48)};
        line-height: ${trans(48)};
        border-radius: ${trans(24)};
        text-align: center;
        border: 1px solid #F06E1E;
        font-size: ${trans(26)};
        color: #F06E1E;
      }
    }
    .desc {
      margin-top: ${trans(37)};
      font-size: ${trans(30)};
      color: #9C9C9C;
      &>span {
        color: #000000;
      }
    }
  }
  .verify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>p {
      font-size: ${trans(30)};
      color: #000000;
    }
    &>span {
      display: flex;
      align-items: center;
      font-size: ${trans(30)};
      color: #A2A2A2;
    }
  }
  .rule {
    &>h6 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: ${trans(32)};
      &>i {
        width: ${trans(132)};
        height: 1px;
        background: #DADADA;
      }
      &>span {
        margin: 0 ${trans(58)};
        font-size: ${trans(24)};
        color: #333333;
      }
    }
    .tips {
      padding: 0 ${trans(30)};
      line-height: ${trans(32)};
      font-size: ${trans(24)};
      color: #B1B1B1;
    }
  }
  .submit {
    display: block;
    width: ${trans(625)};
    margin: 0 auto;
    margin-top: ${trans(67)};
    height: ${trans(100)};
    border-radius: ${trans(50)};
    background: #F08B1E;
    border: none;
    outline: none;
    font-size: ${trans(32)};
    color: #FEFFFF;
  }
`

export default WalletWrap