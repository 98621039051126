import React, {useCallback, useEffect, useRef, useState} from 'react'
import { RouteChildrenProps, Link } from 'react-router-dom'
import LiveDetailWrap from './style'
import http from '../../common/js/http'
import DPlayer from 'dplayer'
import {Tabs, Toast, Modal} from 'antd-mobile'
import { connect } from 'react-redux'
import Message from '../../components/message/message'
import {defaultAvatar} from '../../common/js/config'
import DetailRank from '../../components/detail-rank/detail-rank'
import '../score-home/height.css'
import { setItem, getItem } from "../../common/js/storage";
import {getDate} from "../../common/util/transtime";

const alert = Modal.alert

interface LiveDetailProps extends RouteChildrenProps {
  userInfo: any
}

const tabs = [
  {
    key: '0',
    title: <div>聊天</div>
  },{
    key: '1',
    title: <div>主播</div>
  },{
    key: '2',
    title: <div>贡献榜</div>
  }
]

const LiveDetail: React.FC<LiveDetailProps> = ({ history, match, userInfo}) => {
  const [detail, setDetail] = useState<any>({})
  const [anchor, setAnchor] = useState<any>({})
  const [isAttended, setAttended] = useState<boolean>(false)

  const player = useRef<DPlayer>()
  const watchTimer = useRef<any>()

  const changeWatchTime = useCallback(() => {
    if (!userInfo.id) return

    watchTimer.current = setInterval(() => {
      if (player.current!.video.paused) return
      const time = Number(getItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}watchTime`))
      setItem(`${userInfo.id}${getDate(Date.now() / 1000, 'yy-mm-dd')}watchTime`, (time + 1000).toString())
    }, 1000)
  }, [userInfo])

  const createPlayer = useCallback((url, pic) => {
    player.current = new DPlayer({
      container: document.getElementById('player'),
      video: {
        url: url.replace('.flv', '.m3u8'),
        pic
      },
      autoplay: true,
      loop: true
    })
    // @ts-ignore
    player.current.on('play', (e) => {
      changeWatchTime()
    })
  }, [changeWatchTime])

  const getAnchor = useCallback((detail) => {
    http.post('/api/Anchor/getAnchorInfo', {
      anchorid: (match?.params as { id: string }).id
    })
      .then(({ data: { data } }) => {
        setAnchor(data)
      })
  }, [match])

  useEffect(() => {
    const { id } = match!.params as {id: string}
    http.post('/webapi/live/getLivePageData', {
      anchorid: id
    })
      .then(({data: { data }}) => {
        getAnchor(data)
        if (!data.live) {
          setTimeout(() => {
            Toast.fail('主播休息中')
          }, 1000)
          return
        }
        setDetail(data.live)
        createPlayer(data.live.pull_url, data.live.thumb)
      })

    return () => {
      if (player.current) {
        player.current.destroy()
      }
      clearInterval(watchTimer.current)
    }
  }, [match, getAnchor, createPlayer])

  const focus = useCallback(() => {
    if (!userInfo.id) {
      alert('您还未登录', '请登录使用完整功能', [
        {text: '取消', onPress: () => {}},
        {text: '确定', onPress: () => { history.push('/login') }}
      ])
      return
    }
    const type = anchor.isattent === 0 ? 1 : 0
    http.post('/api/Anchor/attentAnchor', {
      anchorid: anchor.id,
      type
    })
      .then(({ data }) => {
        if (data.msg) {
          Toast.fail(data.msg)
          return
        }
        setAnchor({
          ...anchor,
          isattent: type
        })
        setAttended(Boolean(type))
      })
  }, [anchor, userInfo, history])

  return (
    <LiveDetailWrap>
      <div id="player" />
      <Tabs
        tabBarUnderlineStyle={{transform: 'scaleY(1.2) scaleX(0.2)'}}
        prerenderingSiblingsNumber={0}
        initialPage={'3'}
        swipeable={false}
        animated={false}
        tabs={tabs}>
        <div className="content" key='0'>
          <Message isAttend={isAttended} roomId={'LIVEROOM_' + (match?.params as { id: string }).id}/>
        </div>
        <div className="content" key='1'>
          <div className="anchor">
            <img className='avatar' src={anchor.avatar || defaultAvatar} alt="" />
            <p className='name'>{anchor.nick_name}</p>
            <p className='id'>ID:{anchor.id}</p>
            <div className="center">
              <p>{detail.hot}<br/><span>人气</span></p>
              <p>{anchor.fans_count}<br/><span>粉丝</span></p>
            </div>
            <p className="r-title">主播公告</p>
            <p className='r-content'>{detail.title}</p>
          </div>
        </div>
        <div className="content" key='2'>
          <DetailRank anchorId={detail.anchorid} />
        </div>
      </Tabs>
      <button
        onClick={focus}
        className={anchor.isattent ? 'focus active' : 'focus'}
      >
        <img src={require('./guanzhu@2x.png')}alt=""/>{anchor.isattent ? '已关注': '关注'}
      </button>
      <Link to="/" className="go-home">首页</Link>
    </LiveDetailWrap>
  )
}

const mapState = (state: any) => ({
  userInfo: state.userInfo
})

export default connect(mapState, null)(LiveDetail)
