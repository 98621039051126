export const raceStatus: string[] = ['比赛异常', '未开赛', '上半场', '中场', '下半场', '加时赛', '加时赛', '点球对决', '完场', '推迟', '中断', '腰斩', '取消', '待定']

export const defaultAvatar = require('./moren@2x.png')

export const ballMap: any = {
  '-5': '受五球',
  '-4.75': '受五球/四球半',
  '-4.5': '受四球半',
  '-4.25': '受四球半/四球',
  '-4': '受四球',
  '-3.75': '受四球/三球半',
  '-3.5': '受三球半',
  '-3.25': '受三球半/三球',
  '-3': '受三球',
  '-2.75': '受三球/两球半',
  '-2.5': '受两球半',
  '-2.25': '受两球半/两球',
  '-2': '受两球',
  '-1.75': '受两球/一球半',
  '-1.5': '受一球半',
  '-1.25': '受一球半/一球',
  '-1': '受一球',
  '-0.75': '受一球/球半',
  '-0.5': '受半球',
  '-0.25': '受半球/平手',
  '0': '平手',
  '0.25': '平手/半球',
  '0.5': '半球',
  '0.75': '半球/一球',
  '1': '一球',
  '1.5': '一球/球半',
  '1.75': '球半/两球',
  '2': '两球',
  '2.25': '两球/两球半',
  '2.5': '两球半',
  '2.75': '两球半/三球',
  '3': '三球',
  '3.25': '三球/三球半',
  '3.5': '三球半',
  '3.75': '三球半/四球',
  '4': '四球',
  '4.25': '四球/四球半',
  '4.5': '四球半',
  '4.75': '四球半/五球',
  '5': '五球'
}
