import React, { useState, useEffect, memo, useCallback } from 'react'
import {Carousel, Icon} from 'antd-mobile'
import RecWrap from './style'
import http from '../../common/js/http'
import trans from '../../common/util/px'
import LiveItem from '../../components/live-item/live-item'
import BottomLoading from '../../components/bottom-loading/bottom-loading'
import { Link, useHistory } from "react-router-dom";
import RaceItem from "./race-item";

const Recommend = () => {
  const [banners, setBanner] = useState<any[]>([])
  const [races, setRaces] = useState<any[]>([])
  const [anchors, setAnchors] = useState<any[]>([])
  const [hots, setHots] = useState<any[]>([])
  const [allLives, setAll] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [isFinished, setFinished] = useState<boolean>(true)

  const history = useHistory()

  useEffect(() => {
    http.post('/api/Ads/getHomeScrollAd')
      .then(({ data: { data } }) => {
        setBanner(data)
      })

    http.post('/api/home/getHomeData', {
      page: 1,
      size: 20
    })
      .then(({data: { data }}) => {
        setAnchors(data.rec_anchors)
        setHots(data.hot)
        setAll(data.lives)
        setFinished(data.lives.length < 20)
        const races = []
        for (let i = 0; i < Math.floor(data.rec_matchs.length / 2); i++) {
          races.push(data.rec_matchs.slice(i, i + 2))
        }
        setRaces(races)
        setLoading(false)
      })
  }, [])

  const loadMore = useCallback(() => {
    if (loading) return
    setLoading(true)
    http.post('/api/home/getHomeData', {
      page: page + 1,
      size: 20,
      hideLoading: page + 1 > 1
    })
      .then(({data: { data }}) => {
        setAll(allLives.concat(data.lives))
        setPage(page + 1)
        setLoading(false)
        setFinished(data.lives.length < 20)
      })
  }, [loading, page, allLives])

  return (
    <RecWrap>
      <div className="icon-search">
        <img src={require('./logo_share.png')} alt=""/>
        <Icon type='search' onClick={() => { history.push('/search') }} />
      </div>
      <div className="banner-wrap" onTouchStart={(e) => {e.stopPropagation()}}>
        {banners.length ? <Carousel
          className="my-carousel"
          autoplay={false}
          infinite
          dotStyle={{ marginBottom: trans(20), width: trans(21), height: trans(5), background: 'rgba(255, 255, 255, 0.4)', borderRadius: trans(5) }}
          dotActiveStyle={{ marginBottom: trans(20), width: trans(21), height: trans(5), background: '#F06E1E', borderRadius: trans(5) }}
          beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
          afterChange={index => console.log('slide to', index)}
        >
          {
            banners.map((item: any) => {
              return <a href={item.jump_url} className='banner-item' style={{backgroundImage: `url(${item.image_url})`}} key={item.id}>{''}</a>
            })
          }
        </Carousel> : null}
      </div>
      {
        races.length ? <Carousel
          className="race-carousel"
          autoplay={false}
          dots={false}
          infinite
        >
          {
            races.map((item: any, index) => {
              return <div className="race-banner" key={index}>
                {item.map((race: any) => {
                  return <RaceItem race={race} key={race.id} />
                })}
                <img className="dot" src={require('./more1@2x.png')} alt=""/>
              </div>
            })
          }
        </Carousel> : null
      }
      <div className="anchors">
        <h4 className='sub-title'><i/>主播推荐</h4>
        <div className="list">
          {
            anchors.map((item) => {
              return <Link to={'/live-detail/' + item.id} key={item.id}>
                <div className="icon">
                  <img src={item.avatar} className='avatar' alt=""/>
                  <img src={require('./ic_voice@2x.png')} className='voice' alt=""/>
                </div>
                <p className='text-overflow'>{ item.nick_name }</p>
              </Link>
            })
          }
        </div>
      </div>
      <div className="hot">
        <h4 className='sub-title'><i/>热门推荐</h4>
        <div className="hot-list">
          {
            hots.map((item: any, index) => {
              return <LiveItem key={index} live={item} />
            })
          }
        </div>
      </div>
      <div className="hot no-border">
        <h4 className='sub-title'><i/>全部直播</h4>
        <div className="hot-list">
          {
            allLives.map((item: any, index) => {
              return <LiveItem key={index} live={item} />
            })
          }
        </div>
      </div>
      {!isFinished && <BottomLoading loadMore={loadMore}/>}
    </RecWrap>
  )
}

export default memo(Recommend)
