import styled from 'styled-components'
import trans from '../../common/util/px'

const LiveDetailWrap = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  #player {
    width: 100%;
    height: ${trans(450)};
    background-color: #000;
  }
  .am-tabs-default-bar {
    width: ${trans(604)};
    overflow: hidden;
  }
  .focus {
    position: absolute;
    right: 0;
    top: ${trans(450)};
    height: 43.5px;
    padding: 0 ${trans(20)};
    outline: none;
    border: none;
    background-color: #F06E1E;
    font-size: ${trans(26)};
    color: #FFFFFF;
    &>img {
      width: ${trans(30)};
      height: ${trans(26)};
      margin-right: ${trans(11)};
      vertical-align: top;
      margin-top: ${trans(2)};
    }
    &.active {
      opacity: 0.6;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - ${trans(450)});
    overflow: hidden;
    background-color: #F4F7FF;
    .anchor {
      width: ${trans(690)};
      margin: 0 auto;
      margin-top: ${trans(109)};
      border-radius: ${trans(10)};
      background-color: #fff;
      display: table;
      text-align: center;
      .avatar {
        display: block;
        width: ${trans(115)};
        margin: 0 auto;
        height: ${trans(115)};
        border-radius: 50%;
        margin-top: -${trans(58)};
      }
      .name {
        padding-top: ${trans(19)};
        padding-bottom: ${trans(12)};
        font-size: ${trans(28)};
        color: #333333;
      }
      .id {
        padding-bottom: ${trans(44)};
        font-size: ${trans(20)};
        color: #929292;
      }
      .center {
        display: flex;
        padding: 0 ${trans(196)};
        justify-content: space-between;
        margin-bottom: ${trans(54)};
        &>p {
          text-align: center;
          font-size: ${trans(30)};
          color: #333333;
          &>span {
            display: block;
            margin-top: ${trans(21)};
            font-size: ${trans(24)};
          }
        }
      }
      .r-title {
        margin-bottom: ${trans(31)};
        font-size: ${trans(24)};
        color: #757575;
      }
      .r-content {
        padding-bottom: ${trans(38)};
        font-size: ${trans(28)};
        color: #333333;
      }
    } 
  }
  .go-home {
    position: fixed;
    right: ${trans(20)};
    bottom: ${trans(250)};
    width: ${trans(80)};
    height: ${trans(80)};
    line-height: ${trans(80)};
    border-radius: 50%;
    background: #F06E1E;
    text-align: center;
    font-size: ${trans(26)};
    color: #fff;
  }
`

export default LiveDetailWrap
