import styled from 'styled-components'
import trans from '../../common/util/px'
import {Link} from "react-router-dom";

const PlanItemWrap = styled(Link)`
  display: block;
  padding-top: ${trans(30)};
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #F4F4F4;
    transform: scaleY(0.5);
  }
  .top {
    display: flex;
    align-items: center;
    &>img {
      width: ${trans(59)};
      height: ${trans(59)};
      border-radius: 50%;
      margin-right: ${trans(15)};
    }
    .right {
      &>p {
        margin-bottom: ${trans(11)};
        font-size: ${trans(26)};
        color: #333333;
      }
      .bottom {
        display: flex;
        &>p {
          margin-right: ${trans(14)};
          padding: 0 ${trans(16)};
          height: ${trans(30)};
          line-height: ${trans(26)};
          border-radius: ${trans(15)};
          background-color: #FFD7D7;
          border: 1px solid #E42C2C;
          font-size: ${trans(20)};
          color: #E42C2C;
          &:last-child {
            background-color: #F0F9FF;
            border: 1px solid #6ABEFF;
            font-size: ${trans(20)};
            color: #6ABEFF;
          }
        }
      }
    }
  }
  &>h6 {
    margin: ${trans(20)} 0;
    font-size: ${trans(30)};
    color: #333333;
  }
  .match {
    display: flex;
    margin-bottom: ${trans(27)};
    padding: ${trans(20)} ${trans(24)} ${trans(20)} ${trans(20)};
    justify-content: space-between;
    align-items: center;
    background-color: #F0F9FF;
    border-radius: ${trans(5)};
    .left {
      .time {
        display: flex;
        align-items: center;
        margin-bottom: ${trans(13)};
        font-size: ${trans(24)};
        color: #747474;
        &>span {
          width: ${trans(58)};
          height: ${trans(30)};
          margin-right: ${trans(10)};
          line-height: ${trans(26)};
          background-color: #F0F9FF;
          border: 1px solid #6ABEFF;
          font-size: ${trans(20)};
          color: #6ABEFF;
          text-align: center;
        }
      }
      .team {
        font-size: ${trans(28)};
        color: #333333;
      }
    }
    &>button {
      padding: 0 ${trans(10)};
      height: ${trans(51)};
      border-radius: ${trans(6)};
      background: linear-gradient(96deg,rgba(255,117,34,1),rgba(255,196,53,1));
      border: none;
      outline: none;
      font-size: ${trans(26)};
      color: #FFFFFF;
    }
  }
  .ear-time {
    padding-bottom: ${trans(14)};
    font-size: ${trans(24)};
    color: #979797;
  }
`

export default PlanItemWrap