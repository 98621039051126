import styled from 'styled-components'
import trans from '../../common/util/px'

const LiveRecordWrap = styled.div`
  height: 100vh;
  .content {
    height: calc(100vh - ${trans(90)});
    overflow: hidden;
    background-color: #F4F4F4;
    .item {
      margin: 0 ${trans(30)};
      margin-top: ${trans(18)};
      padding: 0 ${trans(27)};
      border-radius: ${trans(10)};
      background-color: #fff;
      .time {
        height: ${trans(77)};
        line-height: ${trans(77)};
        font-size: ${trans(24)};
        color: #969696;
        border-bottom: 1px solid #F3F3F3;
      }
      &>p {
        height: ${trans(76)};
        line-height: ${trans(76)};
        font-size: ${trans(28)};
        color: #333333;
      }
    }
  }
`

export default LiveRecordWrap