import styled from 'styled-components'
import trans from '../../common/util/px'

const BindPhoneWrap = styled.div`
  .input {
    width: ${trans(625)};
    height: ${trans(100)};
    border-radius: ${trans(50)};
    margin: 0 auto;
    margin-top: ${trans(47)};
    display: flex;
    align-items: center;
    padding-left: ${trans(38)};
    background-color: #F4F4F4;
    &>input {
      flex: 1;
      outline: none;
      border: none;
      background-color: transparent;
      font-size: ${trans(30)};
    }
    &>button {
      width: ${trans(152)};
      height: ${trans(55)};
      margin-right: ${trans(20)};
      border-radius: ${trans(27)};
      background-color: #F08B1E;
      font-size: ${trans(24)};
      color: #FFFFFF;
      outline: none;
      border: none;
    }
  }
  &>button {
    display: block;
    width: ${trans(625)};
    margin: 0 auto;
    margin-top: ${trans(151)};
    height: ${trans(100)};
    border-radius: ${trans(50)};
    background-color: #F08B1E;
    font-size: ${trans(32)};
    color: #FEFFFF;
    outline: none;
    border: none;
  }
`

export default BindPhoneWrap