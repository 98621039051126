import styled from 'styled-components'
import trans from '../../common/util/px'

const ApplyFormWrap = styled.div`
  .head {
    background-color: #F08B1E;
    .header {
      display: flex;
      height: ${trans(90)};
      align-items: center;
      &>h2 {
        margin-left: ${trans(220)};
        font-size: ${trans(36)};
        color: #FFFFFF;
      }
    }
    .step {
      margin-top: ${trans(50)};
      position: relative;
      height: ${trans(130)};
      overflow: hidden;
      .line {
        display: block;
        width: ${trans(501)};
        margin: 0 auto;
        margin-top: ${trans(16)};
        height: ${trans(1)};
        background-color: #FFDFCB;
      }
      .circle {
        position: absolute;
        width: ${trans(632)};
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        .one {
          &>i {
            display: block;
            margin: 0 auto;
            width: 0;
            height: 0;
            margin-top: ${trans(8)};
            margin-bottom: ${trans(9)};
            border: ${trans(9)} solid #FFDFCB;
            border-radius: 50%;
            &.big {
              width: ${trans(14)};
              height: ${trans(14)};
              margin-top: 0;
              margin-bottom: 0;
              border-color: #fff;
              background-color: #F08B1E;
            }
          }
          &>p {
            margin-top: ${trans(22)};
            font-size: ${trans(24)};
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .phone-binded {
    text-align: center;
    &>img {
      width: ${trans(455)};
      height: ${trans(380)};
    }
    &>p {
      padding-top: ${trans(55)};
      font-size: ${trans(28)};
      color: #A1A1A1;
    }
  }
  .verified {
    .input {
      margin-left: ${trans(30)};
      height: ${trans(112)};
      display: flex;
      align-items: center;
      padding-right: ${trans(30)};
      border-bottom: 1px solid #F4F4F4;
      &>label {
        font-size: ${trans(30)};
        color: #666666;
      }
      &>input {
        flex: 1;
        padding-left: ${trans(40)};
        font-size: ${trans(30)};
        color: #000000;
        outline: none;
        border: none;
        text-align: right;
      }
    }
    .upload {
      padding: 0 ${trans(30)};
      margin-top: ${trans(30)};
      &>h4 {
        display: flex;
        align-items: center;
        font-size: ${trans(30)};
        color: #333333;
        margin-bottom: ${trans(25)};
        &>i {
          width: ${trans(5)};
          height: ${trans(22)};
          margin-right: ${trans(12)};
          border-radius: ${trans(3)};
          background-color: #F06E1E;
        }
      }
      .card {
        display: flex;
        justify-content: space-between;
        .item {
          &>img {
            width: ${trans(224)};
            height: ${trans(146)};
          }
          &>p {
            margin-top: ${trans(11)};
            text-align: center;
            font-size: ${trans(24)};
            color: #959595;
          }
        }
      }
    }
    .tips {
      padding: 0 ${trans(40)};
      margin-top: ${trans(53)};
      line-height: ${trans(30)};
      font-size: ${trans(24)};
      color: #959595;
      &>span {
        color: #FF3B3B;
      }
    }
    &>button {
      display: block;
      width: ${trans(625)};
      margin: 0 auto;
      margin-top: ${trans(64)};
      height: ${trans(100)};
      border-radius: ${trans(50)};
      background-color: #F08B1E;
      font-size: ${trans(32)};
      color: #FEFFFF;
      outline: none;
      border: none;
    }
    .is-end {
      padding-top: ${trans(103)};
      text-align: center;
      &>img {
        width: ${trans(455)};
        height: ${trans(380)};
      }
      &>p {
        padding-top: ${trans(90)};
        font-size: ${trans(28)};
        color: #A1A1A1;
      }
    }
  }
  .input-info {
    .input {
      display: flex;
      height: ${trans(112)};
      align-items: center;
      justify-content: space-between;
      padding: 0 ${trans(30)};
      border-bottom: 1px solid #F4F4F4;
      &>label {
        font-size: ${trans(30)};
        color: #666666;
      }
      &>p {
        display: flex;
        align-items: center;
        font-size: ${trans(30)};
      }
      &>input {
        flex: 1;
        padding-left: ${trans(40)};
        text-align: right;
        font-size: ${trans(30)};
        outline: none;
        border: none;
        &::-webkit-input-placeholder{
            color: #B4B4B4;
        }
      }
    }
    .sign {
      padding-top: ${trans(46)};
      &>h6 {
        margin-bottom: ${trans(45)};
        padding: 0 ${trans(23)};
        font-size: ${trans(30)};
        color: #666666;
      }
      &>input {
        width: 100%;
        box-sizing: border-box;
        padding: 0 ${trans(23)};
        font-size: ${trans(30)};
        outline: none;
        border: none;
      }
    }
    &>button {
      display: block;
      width: ${trans(625)};
      margin: 0 auto;
      margin-top: ${trans(180)};
      margin-bottom: ${trans(54)};
      height: ${trans(100)};
      border-radius: ${trans(50)};
      background-color: #F08B1E;
      font-size: ${trans(32)};
      color: #FEFFFF;
      outline: none;
      border: none;
    }
    .user-desc {
      width: 100%;
      box-sizing: border-box;
      height: ${trans(297)};
      padding: ${trans(55)} ${trans(28)};
      outline: none;
      border: none;
      font-size: ${trans(28)};
      border-bottom: ${trans(18)} solid #F4F4F4;
      resize: none;
    }
  }
`

export default ApplyFormWrap