import React from 'react'
import { RaceDescWrap } from './style'
import {getDate} from '../../common/util/transtime'
import { raceStatus } from '../../common/js/config'
import {getKey} from '../../common/util/getKey'
import { Toast } from 'antd-mobile'

import collect from './ic_shoucang@2x.png'
import collected from './ic_shoucang_pre@2x.png'

interface RaceDescProps {
  desc: any
  history: any
  collection(): void
}

const RaceDesc: React.FC<RaceDescProps> = ({ desc, history, collection }) => {
  return (
    <RaceDescWrap>
      <div className="top">
        <img className='back' onClick={history.goBack} src={require('./ic_back@2x.png')} alt=""/>
        <div className='center'>
          <p>{ desc.matchevent && desc.matchevent.short_name_zh }</p>
          <p>{ getDate(desc.time || 0, 'yy年mm月dd日  hh:mt') }</p>
        </div>
        <img onClick={collection} className='collect' src={desc.attented ? collected : collect} alt=""/>
      </div>
      <p className="status">{raceStatus[desc.status]}</p>
      <div className="team-wrap">
        <div className="team">
          <img src={getKey(desc, 'hometeam.logo')} alt=""/>
          <p>{getKey(desc, 'hometeam.short_name_zh')}</p>
        </div>
        <div className="score">{desc.home_score}-{desc.away_score}</div>
        <div className="team">
          <img src={getKey(desc, 'awayteam.logo')} alt=""/>
          <p>{getKey(desc, 'awayteam.short_name_zh')}</p>
        </div>
      </div>
      <button onClick={() => {
        if (desc.status <= 1) {
          Toast.info(raceStatus[desc.status])
          return
        }
        Toast.info('暂无视频', undefined, undefined, false)
      }}>视频直播</button>
    </RaceDescWrap>
  )
}

export default RaceDesc
