import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ScoreHome from '../pages/score-home/score'
import RaceFilter from '../pages/reace-filter/race-filter'
import RaceDetail from '../pages/race-detail/race-detail'

const Score = () => {
  return (
    <Switch>
      <Route exact path='/score' component={ScoreHome} />
      <Route exact path='/score/filter' component={RaceFilter} />
      <Route exact path='/score/detail/:id' component={RaceDetail} />
    </Switch>
  )
}

export default Score