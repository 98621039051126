export const getItem = (key: string) => {
  const data: any = localStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (e) {
    return data
  }
}

export const setItem = (key: string, data: any) => {
  if (typeof data !== 'string') data = JSON.stringify(data)
  localStorage.setItem(key, data)
}

export const removeItem = (key: string) => {
  localStorage.removeItem(key)
}
