import React, {useCallback, useState} from "react";
import SearchWrap from "./style";
import {Toast} from "antd-mobile";
import http from '../../common/js/http'
import {defaultAvatar} from "../../common/js/config";
import { useHistory } from 'react-router-dom'

const Search: React.FC = () => {
  const [searchKey, setKey] = useState<string>('')
  const [list, setList] = useState<any[]>([])
  const [isLoad, setLoad] = useState<boolean>(false)

  const history = useHistory()

  const submit = useCallback((e: any) => {
    e.preventDefault()
    if (!searchKey) {
      Toast.fail('请输入搜索内容', 1, undefined, false)
      return
    }
    http.post('/webapi/search/anchor', {
      keyword: searchKey
    })
      .then(({ data: { data } }) => {
        setList(data.anchors)
        setLoad(true)
      })
  }, [searchKey])

  const attending = useCallback((item: any, index: number) => {
    http.post('/api/Anchor/attentAnchor', {
      anchorid: item.id,
      type: Number(!item.isattent)
    })
      .then(() =>{
        const copy = JSON.parse(JSON.stringify(list))
        copy[index].isattent = Number(!item.isattent)
        setList(copy)
      })
  }, [list])

  return (
    <SearchWrap>
      <form onSubmit={submit}>
        <div className="input">
          <img src={require('./ic_search@2x.png')} alt=""/>
          <input onChange={(e) => { setKey(e.target.value) }} value={searchKey} type="text"/>
          <img onClick={() => { setKey(''); setLoad(false); setList([]) }} src={require('./ic_delete@2x.png')} alt=""/>
        </div>
        <button type='submit'>搜索</button>
      </form>
      <div className="list">
        {
          list.map((item, index) => {
            return (
              <div className="anchor" key={item.id} onClick={() => history.push('/live-detail/' + item.id)}>
                <img src={item.avatar || defaultAvatar} alt=""/>
                <div className="center">
                  <h4>{item.nick_name}</h4>
                  <p>{item.signature}</p>
                </div>
                <button onClick={(e) => { e.stopPropagation(); attending(item, index) }} className={item.isattent ? 'active' : ''}>{item.isattent ? '已关注' : '+关注'}</button>
              </div>
            )
          })
        }
      </div>
      {isLoad && !list.length ? <div className="no-res">
        <img src={require('./bg@2x.png')} alt=""/>
        <p>未搜索到任何结果</p>
      </div> : null}
    </SearchWrap>
  )
}

export default Search
