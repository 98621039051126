import styled from 'styled-components'
import trans from '../../common/util/px'

const ListBody = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 0 ${trans(30)};
  padding-top: ${trans(35)};
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
`

export default ListBody