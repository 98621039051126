import React, {useCallback, useEffect, useRef, useState} from "react";
import ScrollWrap from "../../components/scroll-wrap/scroll-wrap";
import http from '../../common/js/http'
import PlanItem from "../../components/plan-item/plan-item";
// import mock from '../../common/util/mock'
import trans from "../../common/util/px";
import BottomLoading from "../../components/bottom-loading/bottom-loading";

interface MyListProps {
  type: string
}

const MyList: React.FC<MyListProps> = ({ type }) => {
  const [list, setList] = useState<any[]>([])
  const [showLoading, setLoading] = useState<boolean>(false)

  const page = useRef<number>(1)
  const backUp = useRef<any[]>([])

  const getList = useCallback((loading = false) => {
    return http.post('/api/Youliao/getlistMine', {
      page: page.current,
      size: 10,
      status: type,
      hideLoading: loading
    })
      .then(({ data: { data } }) => {
        // data.forEach((item: any) => {
        //   item.match = mock
        // })
        setLoading(data.length >= 10)
        backUp.current = [...backUp.current, ...data]
        setList([...backUp.current])
      })
  }, [type])

  useEffect(() => {
    getList()
  }, [getList])

  const loadMore = useCallback(() => {
    if (!showLoading) return Promise.resolve()
    page.current++
    return getList(true)
  }, [showLoading, getList])

  return (
    <ScrollWrap loadMore={loadMore}>
      <div style={{ padding: `0 ${trans(30)}` }}>
        {
          list.map((item) => {
            return item.match ? <PlanItem showUser={false} item={item} key={item.id} /> : null
          })
        }
      </div>
      {showLoading && <BottomLoading/>}
    </ScrollWrap>
  )
}

export default MyList