interface SearchTypes {
  [key: string]: string
}

const parseSearch = (search: string) => {
  if (search.indexOf('?') >= 0) {
    search = search.split('?')[1]
  }
  const keys: string[] = search.split('&')
  const res: SearchTypes = {}
  for (let i = 0, len = keys.length; i < len; i++) {
    const da = keys[i].split('=')
    res[da[0]] = da[1]
  }

  return res
}

export default parseSearch